import * as Actions from '../actionTypes'
import { buildURLQuery } from '../../helpers/URLHelper'
import { intervalToDate } from '../../helpers/intervals'

// Initial times
const beforeTime = new Date()
beforeTime.setDate(beforeTime.getDate() - 1)
const currentTime = new Date()

const initialState = {
  table: {},
  station: {},
  fields: [],
  interval: '1d',
  period: '10m',

  sig_digits: localStorage.getItem('sig_digits') || 5,

  start_time: beforeTime.toISOString(),
  end_time: currentTime.toISOString(),

  urlQuery: '',
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.FILTER: {
      // set sig_digits
      let sigDigits = state.sig_digits
      if (action.payload.sig_digits !== undefined) {
        sigDigits = action.payload.sig_digits
        localStorage.setItem('sig_digits', sigDigits)
      }

      // filter by fields
      let selectedFields = state.fields
      if (action.payload.fields) selectedFields = action.payload.fields
      // filter by table
      let selectedTable = state.table
      if (action.payload.table)
        selectedTable = action.payload.table.table_name
          ? action.payload.table
          : state.table
      // filter by station
      let selectedStation = state.station
      if (action.payload.station) {
        selectedStation = action.payload.station.id
          ? action.payload.station
          : state.station
        selectedTable = {}
        selectedFields = []
      }
      // set interval

      let selectedStartTime = state.start_time
      let selectedEndTime = state.end_time
      let selectedInterval = action.payload.interval || state.interval
      if (selectedInterval !== 'custom') {
        selectedStartTime = intervalToDate(selectedInterval).toISOString()
        selectedEndTime = new Date().toISOString()
      }
      if (action.payload.start_time) {
        selectedStartTime = action.payload.start_time
        selectedInterval = 'custom'
      }
      if (action.payload.end_time) {
        selectedEndTime = action.payload.end_time
        selectedInterval = 'custom'
      }

      //set period
      let selectedPeriod = action.payload.period || state.period

      let urlQuery = buildURLQuery({
        station_id: selectedStation.id,
        table_name: selectedTable.table_name,
        interval: selectedInterval,
        period: selectedPeriod,
        sig_digits: sigDigits,
        start_time: selectedInterval === 'custom' ? selectedStartTime : '',
        end_time: selectedInterval === 'custom' ? selectedEndTime : '',
      })

      return {
        ...state,
        station: selectedStation,
        table: selectedTable,
        fields: selectedFields,
        interval: selectedInterval,
        period: selectedPeriod,
        sig_digits: sigDigits,
        start_time: selectedStartTime,
        end_time: selectedEndTime,
        urlQuery,
      }
    }
    case Actions.SET_VIEW: {
      const newFilter = action.payload.filter
      if (newFilter)
        return {
          ...state,
          ...action.payload.filter,
        }
      return { ...state }
    }
    default:
      return state
  }
}
