import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isMaster } from 'helpers/roleHandler'
import {
  clearTestData,
  testDataloggerConnection,
  createStation,
  fetchDataloggerTables,
  updateStation,
  fetchLicences,
  fetchUsers,
} from 'redux/actions'

import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import { DateTimePicker } from 'react-widgets'
import { secondsToObject, objectToSeconds } from 'helpers/timeHelper'
import { boolToColor } from 'helpers/colors'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import Switch from 'components/CustomUI/Switch'

moment.locale('hr')
momentLocalizer()

const AddOrEditStation = props => {
  const { defaultTab, label, style = {} } = props
  const {
    station,
    className,
    testConnectionResponse,
    stationTables,
    licences,
    users,
    currentUser,
    loading,
    clearTestData,
    testDataloggerConnection,
    fetchDataloggerTables,
    createStation,
    updateStation,
    fetchLicences,
    fetchUsers,
  } = props

  const [modal, setModal] = useState(false)
  const [formStep, setFormStep] = useState(defaultTab ? Number(defaultTab) : 0)

  const initialStationValues = {
    owner_id: undefined,

    alias: '',
    ip_address: '',
    extra_response_time: 0,
    security_code: '',
    collection_enabled: false,
    collect_base_date: new Date(new Date().setHours(0, 0, 0, 0)),
    collection_interval: 600,
    collection_retry_interval: 600,
    number_of_retries: 3,
    tables: [],

    licence: {},
    changedTables: [],
  }

  const [stationValues, setStationValues] = useState(initialStationValues)
  const [collectionInterval, setCollectionInterval] = useState(
    secondsToObject(initialStationValues.collection_interval)
  )
  const [retryInterval, setRetryInterval] = useState(
    secondsToObject(initialStationValues.collection_retry_interval)
  )

  useEffect(() => {
    if (stationValues.owner_id) {
      fetchLicences({ user_id: stationValues.owner_id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationValues.owner_id, station])

  useEffect(() => {
    if (licences.length > 0) {
      setStationValues({ ...stationValues, licence: licences[0] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licences])

  useEffect(() => {
    setStationValues({
      ...stationValues,
      collection_interval: objectToSeconds(collectionInterval),
      collection_retry_interval: objectToSeconds(retryInterval),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionInterval, retryInterval])

  const toggle = () => {
    if (!modal) {
      setStationValues({
        ...(station || { ...initialStationValues, owner_id: currentUser.id }),
        changedTables: [],
      })
      setCollectionInterval(
        secondsToObject((station || initialStationValues).collection_interval)
      )
      setRetryInterval(
        secondsToObject(
          (station || initialStationValues).collection_retry_interval
        )
      )
    }
    setModal(!modal)
  }

  useEffect(() => {
    if (modal) {
      fetchUsers()
      if (Number(defaultTab || 0) === 1 || formStep === 1) handleTablesFetch()
    } else {
      clearTestData()
      if (!station) setFormStep(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal])

  const handleTablesFetch = () => {
    fetchDataloggerTables(
      stationValues.ip_address,
      stationValues.extra_response_time,
      stationValues.security_code,
      stationValues.id
    )
  }

  const handleTestConnection = event => {
    event.preventDefault()
    testDataloggerConnection(
      stationValues.ip_address,
      stationValues.extra_response_time,
      stationValues.security_code
    )
  }

  const handleCancel = event => {
    /*
    event.preventDefault()
    if (formStep > 0) {
      setFormStep(formStep - 1)
      return
    }
		*/
    toggle()
  }

  const handleSwitchChange = event => {
    setStationValues({
      ...stationValues,
      [event.target.id]: event.target.checked,
    })
  }

  const handleChange = event => {
    setStationValues({
      ...stationValues,
      [event.target.id]: event.target.value,
    })
  }

  const handleCollectionIntervalChange = event => {
    setCollectionInterval({
      ...collectionInterval,
      [event.target.id]: event.target.value,
    })
  }

  const handleRetryIntervalChange = event => {
    setRetryInterval({
      ...retryInterval,
      [event.target.id]: event.target.value,
    })
  }

  const handleLicenceChange = event => {
    const selectedLicence =
      licences.filter(
        licence => licence.id === Number(event.target.value)
      )[0] || {}
    setStationValues({
      ...stationValues,
      [event.target.id]: selectedLicence,
    })
  }

  const handleCheckboxChange = (event, table) => {
    const exists =
      stationValues.changedTables.filter(
        tableFromList => tableFromList.name === table.name
      ).length > 0
    if (exists) {
      setStationValues({
        ...stationValues,
        changedTables: stationValues.changedTables.filter(
          tableFromList => tableFromList.name !== table.name
        ),
      })
    } else {
      setStationValues({
        ...stationValues,
        changedTables: [...stationValues.changedTables, table],
      })
    }
  }

  const handleNext = event => {
    event.preventDefault()
    if (formStep === 0) {
      handleTablesFetch()
      setFormStep(formStep + 1)
    } else if (formStep === 1) {
      setFormStep(formStep + 1)
    } else {
      handleSave()
    }
  }

  const handleSave = () => {
    if (station) {
      updateStation(stationValues)
    } else {
      createStation(stationValues)
    }
    toggle()
  }

  const setTab = event => {
    const step = Number(event.target.id)
    if (step === 1) {
      handleTablesFetch()
    }
    setFormStep(step)
  }

  const getValidLicences = () => {
    return licences.filter(
      licence =>
        !licence.station_id ||
        (station && station.licence && licence.id === station.licence.id)
    )
  }

  return (
    <div>
      <Button color="primary" onClick={toggle} style={style}>
        {label}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                id="0"
                className={formStep === 0 ? 'active' : ''}
                onClick={setTab}>
                Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                id="1"
                className={formStep === 1 ? 'active' : ''}
                onClick={setTab}>
                Tables
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                id="2"
                className={formStep === 2 ? 'active' : ''}
                onClick={setTab}>
                Collection
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="p-2" activeTab={formStep.toString()}>
            <TabPane tabId="0">
              <Form>
                <FormGroup>
                  <Label for="stationAlias">Name</Label>
                  <Input
                    type="text"
                    id="alias"
                    placeholder="Station Name"
                    value={stationValues.alias}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="stationIP">IP Address</Label>
                  <Input
                    type="text"
                    id="ip_address"
                    placeholder="e.g. 100.20.20.5:1234"
                    value={stationValues.ip_address}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="securityCode">Security Code</Label>
                  <Input
                    type="text"
                    id="security_code"
                    placeholder="None"
                    value={stationValues.security_code || ''}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="extra_response_time">
                    Extra Response Time (seconds)
                  </Label>
                  <Input
                    type="number"
                    id="extra_response_time"
                    placeholder="e.g. 5"
                    value={stationValues.extra_response_time}
                    onChange={handleChange}
                  />
                </FormGroup>
                <div>
                  <FormText
                    color={
                      testConnectionResponse.outcome === 1
                        ? 'success'
                        : 'danger'
                    }>
                    {testConnectionResponse.description}
                  </FormText>
                </div>
                <Button onClick={handleTestConnection}>Test Connection</Button>
                {isMaster() && (
                  <FormGroup>
                    <Label for="licence">Owner</Label>
                    <Input
                      type="select"
                      id="owner_id"
                      value={stationValues.owner_id || 0}
                      onChange={handleChange}>
                      {users.map(user => (
                        <option key={user.id} value={Number(user.id)}>
                          {user.first_name} {user.last_name} ({user.email})
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="licence">Licence</Label>
                  <Input
                    type="select"
                    id="licence"
                    value={stationValues.licence.id}
                    onChange={handleLicenceChange}>
                    <option value={undefined}>None</option>
                    {getValidLicences().map(licence => (
                      <option key={licence.id} value={Number(licence.id)}>
                        {licence.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Form>
            </TabPane>
            <TabPane tabId="1">
              <Form>
                <Label for="collectionTables">Collection Tables</Label>
                {loading && (
                  <div className="mb-3">
                    <FormText color="muted">Loading from station...</FormText>
                  </div>
                )}
                {stationTables.filter(table => !table.station_id).length ===
                  0 &&
                  !loading && (
                    <div className="mb-3">
                      <FormText color="danger">
                        Could not fetch any tables from the station. The station
                        is unreachable.
                      </FormText>
                    </div>
                  )}
                {stationTables &&
                  stationTables.map(table => (
                    <FormGroup check key={table.name}>
                      <Label check>
                        <Input
                          type="checkbox"
                          style={{ opacity: 0.5, visibility: 'visible' }}
                          defaultChecked={
                            stationValues.tables.filter(
                              tableFromList =>
                                tableFromList.name === table.name &&
                                tableFromList.collection_enabled
                            ).length > 0
                          }
                          onChange={event => handleCheckboxChange(event, table)}
                        />{' '}
                        {table.name}
                        <span style={{ fontStyle: 'italic', color: 'gray' }}>
                          {table.station_id && ' - not available'}
                        </span>
                      </Label>
                    </FormGroup>
                  ))}
                {stationTables.length > 0 && (
                  <FormText color="muted">
                    Please select station tables that will be collected
                  </FormText>
                )}
              </Form>
              <Button onClick={handleTablesFetch}>Refresh</Button>
            </TabPane>
            <TabPane tabId="2">
              <Form>
                <Label for="collectionEnabled">Data Collection</Label>
                <FormGroup>
                  <Switch
                    type="switch"
                    id="collection_enabled"
                    label={
                      <span>
                        collection is now{' '}
                        <span
                          style={{
                            color: boolToColor(
                              stationValues.collection_enabled
                            ),
                          }}>
                          {stationValues.collection_enabled
                            ? 'ENABLED'
                            : 'DISABLED'}
                        </span>
                      </span>
                    }
                    checked={stationValues.collection_enabled}
                    onChange={handleSwitchChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="collectBaseDate">Collection Base Date</Label>
                  <DateTimePicker
                    format="DD.MM.YYYY HH:mm"
                    value={new Date(stationValues.collect_base_date)}
                    onChange={value =>
                      handleChange({
                        target: { id: 'collect_base_date', value: value },
                      })
                    }
                  />
                </FormGroup>
                <Label for="collectionInterval">Collection Interval</Label>
                <Row>
                  <Col md={3} className="text-center">
                    <FormGroup>
                      <Input
                        type="number"
                        id="days"
                        placeholder="e.g. 1"
                        value={collectionInterval.days}
                        onChange={handleCollectionIntervalChange}
                      />
                      <Label>days</Label>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="text-center">
                    <FormGroup>
                      <Input
                        type="number"
                        id="hours"
                        placeholder="e.g. 12"
                        value={collectionInterval.hours}
                        onChange={handleCollectionIntervalChange}
                      />
                      <Label>hours</Label>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="text-center">
                    <FormGroup>
                      <Input
                        type="number"
                        id="minutes"
                        placeholder="e.g. 10"
                        value={collectionInterval.minutes}
                        onChange={handleCollectionIntervalChange}
                      />
                      <Label>minutes</Label>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="text-center">
                    <FormGroup>
                      <Input
                        type="number"
                        id="seconds"
                        placeholder="e.g. 0"
                        value={collectionInterval.seconds}
                        onChange={handleCollectionIntervalChange}
                      />
                      <Label>seconds</Label>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="numberOfRetries">
                    Number of Retries on Collection Failure
                  </Label>
                  <Input
                    type="number"
                    id="number_of_retries"
                    placeholder="e.g. 3"
                    value={stationValues.number_of_retries}
                    onChange={handleChange}
                  />
                </FormGroup>
                <Label>Collection Retry Interval</Label>
                <Row>
                  <Col md={3} className="text-center">
                    <FormGroup>
                      <Input
                        type="number"
                        id="days"
                        placeholder="e.g. 1"
                        value={retryInterval.days}
                        onChange={handleRetryIntervalChange}
                      />
                      <Label>days</Label>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="text-center">
                    <FormGroup>
                      <Input
                        type="number"
                        id="hours"
                        placeholder="e.g. 12"
                        value={retryInterval.hours}
                        onChange={handleRetryIntervalChange}
                      />
                      <Label>hours</Label>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="text-center">
                    <FormGroup>
                      <Input
                        type="number"
                        id="minutes"
                        placeholder="e.g. 10"
                        value={retryInterval.minutes}
                        onChange={handleRetryIntervalChange}
                      />
                      <Label>minutes</Label>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="text-center">
                    <FormGroup>
                      <Input
                        type="number"
                        id="seconds"
                        placeholder="e.g. 0"
                        value={retryInterval.seconds}
                        onChange={handleRetryIntervalChange}
                      />
                      <Label>seconds</Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter style={{ borderTop: '1px solid #dee2e6' }}>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          {station ? (
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          ) : (
            <Button color="primary" onClick={handleNext}>
              Next
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    testConnectionResponse: state.dataloggers.testConnectionResponse,
    stationTables: state.dataloggers.tables,
    licences: state.licences.all,
    currentUser: state.account.currentUser,
    users: state.users.all,
    loading: state.loader.loading,
  }
}

const mapDispatchToProps = {
  clearTestData,
  testDataloggerConnection,
  fetchDataloggerTables,
  createStation,
  updateStation,
  fetchLicences,
  fetchUsers,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditStation)
