import React, { useState } from 'react'
import { connect } from 'react-redux'
import { deleteView } from 'redux/actions'

import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

const DeleteView = props => {
  const { children, className, disabled, selectedView, deleteView } = props

  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  const showModal = () => {
    if (!disabled) setModal(true)
  }

  const handleCancel = event => {
    event.preventDefault()
    setModal(false)
  }

  const handleDelete = event => {
    event.preventDefault()
    if (selectedView.id) deleteView(selectedView.id)
    setModal(false)
  }

  return (
    <div onClick={showModal}>
      {children}
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <div>
          <ModalBody>
            <h5>Delete View</h5>
            <p>
              Are you sure you want to delete view <b>{selectedView.name}</b>?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="danger" onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    selectedView: state.views.view,
  }
}

const mapDispatchToProps = {
  deleteView,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteView)
