import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown as caretDown,
  faCaretUp as caretUp,
} from '@fortawesome/free-solid-svg-icons'

const Caret = props => {
  const { down } = props
  if (down) {
    return <FontAwesomeIcon icon={caretDown} size="lg" />
  }
  return <FontAwesomeIcon icon={caretUp} size="lg" />
}

export default Caret
