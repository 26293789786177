import isPromise from 'is-promise'

export default function errorMiddleware() {
  return next => action => {
    // If not a promise, continue on
    if (!isPromise(action.payload)) {
      return next(action)
    }

    return next(action).catch(error => {
      return error
    })
  }
}
