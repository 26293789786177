import React from 'react'
import InviteUser from 'components/InviteUser'

const UsersActionBar = props => {
  return (
    <div className="action-bar">
      <InviteUser label="Invite Users" />
    </div>
  )
}

export default UsersActionBar
