import * as Actions from './actionTypes'
import axios from 'axios'
import { buildURLQuery } from '../helpers/URLHelper'

const reduxStore = require('./store')

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.timeout = 20000

// User Actions
export const fetchUser = id => ({
  type: Actions.FETCH_USER,
  payload: axios.get('/users/' + id),
})
export const fetchProfile = () => ({
  type: Actions.FETCH_USER,
  payload: axios.get('/users/current'),
})
export const updateProfile = content => ({
  type: Actions.UPDATE_PROFILE,
  payload: axios.put('/users/current', content),
})
export const login = (email, password) => ({
  type: Actions.LOGIN,
  payload: axios.post('/users/login', { email, password }),
})
export const logout = () => ({
  type: Actions.LOGOUT,
})
export const relogin = token => ({
  type: Actions.RELOGIN,
  payload: axios.post(
    '/users/relogin',
    {},
    {
      headers: { Authorization: 'bearer ' + token },
    }
  ),
})
export const inviteUser = content => ({
  type: Actions.INVITE_USER,
  payload: axios.post('/users/invite', content),
})
export const fetchInvitedUser = token => ({
  type: Actions.FETCH_INVITED_USER,
  payload: axios.get('/users/verify-invitation/' + token),
})
export const registerInvitedUser = content => ({
  type: Actions.REGISTER_INVITED_USER,
  payload: axios.post('/users/register-invitation', content),
})
export const createUser = content => ({
  type: Actions.CREATE_USER,
  payload: axios.post('/users/register', content),
})
export const updateUser = content => ({
  type: Actions.FETCH_USER,
  payload: axios.put('/users/' + content.id, content),
})
export const deleteUser = id => ({
  type: Actions.DELETE_USER,
  payload: axios.delete('/users/' + id),
})

// Users Actions
export const fetchUsers = query => ({
  type: Actions.FETCH_USERS,
  payload: axios.get('/users' + (query === undefined ? '' : '?query=' + query)),
})

// Stations Actions
export const fetchStations = query => ({
  type: Actions.FETCH_STATIONS,
  payload: axios.get('/stations'),
})

// Station Actions
export const fetchStation = id => ({
  type: Actions.FETCH_STATION,
  payload: axios.get('/stations/' + id),
})
export const autoUpdateStation = id => ({
  type: Actions.FETCH_STATION,
  payload: axios.get('/stations/' + id + '/auto-update'),
})
export const createStation = content => ({
  type: Actions.CREATE_STATION,
  payload: axios.post('/stations', content),
})
export const updateStation = content => ({
  type: Actions.FETCH_STATION,
  payload: axios.put('/stations/' + content.id, content),
})
export const deleteStation = id => ({
  type: Actions.DELETE_STATION,
  payload: axios.delete('/stations/' + id),
})

// Licences Actions
export const fetchLicences = query => ({
  type: Actions.FETCH_LICENCES,
  payload: axios.get('/licences/' + buildURLQuery(query)),
})
export const createLicence = content => ({
  type: Actions.FETCH_LICENCES,
  payload: axios.post('/licences', content),
})
export const updateLicence = content => ({
  type: Actions.UPDATE_LICENCE,
  payload: axios.put('/licences/' + content.id, content),
})

// Datalogger Actions
export const testDataloggerConnection = (address, timeout, security) => ({
  type: Actions.TEST_DATALOGGER_CONNECTION,
  payload: axios.get(
    '/dataloggers/test?address=' +
      address +
      (timeout ? '&timeout=' + timeout : '') +
      '&security=' +
      security
  ),
})
export const fetchDataloggerTables = (
  address,
  timeout,
  security,
  station_id
) => ({
  type: Actions.FETCH_DATALOGGER_TABLES,
  payload: axios.get(
    '/dataloggers/tables?address=' +
      address +
      (timeout ? '&timeout=' + timeout : '') +
      '&security=' +
      security +
      '&station_id=' +
      station_id
  ),
})
export const clearTestData = () => ({
  type: Actions.CLEAR_TEST_DATA,
})

// Table Actions
export const fetchStationTables = station_id => ({
  type: Actions.FETCH_TABLES,
  payload: axios.get('/stations/' + station_id + '/tables'),
})

// Field Actions
export const fetchStationTableFields = (station_id, table_name) => ({
  type: Actions.FETCH_FIELDS,
  payload: axios.get(
    '/stations/' + station_id + '/tables/' + table_name + '/fields'
  ),
})

// Sample Actions
export const fetchSamples = filter => {
  return {
    type: Actions.FETCH_SAMPLES,
    payload: axios.get(
      '/samples' + reduxStore.default.getState().filters.urlQuery
    ),
  }
}
export const fetchSamplesInfo = filter => ({
  type: Actions.FETCH_SAMPLES_INFO,
  payload: axios.get('/samples/info' + filter.urlQuery),
})
export const fetchLastRecord = filter => ({
  type: Actions.FETCH_LASTRECORD,
  payload: axios.get('/samples/last' + filter.urlQuery),
})
export const clearSamples = options => ({
  type: Actions.CLEAR_SAMPLES,
  payload: options,
})
export const fetchSamplesCSV = filter => ({
  type: Actions.FETCH_SAMPLES_CSV,
  payload: axios.get(
    '/samples/csv' + reduxStore.default.getState().filters.urlQuery
  ),
})

// Sample Filters
export const filterByStation = station => ({
  type: Actions.FILTER,
  payload: { station },
})
export const filterByTable = table => ({
  type: Actions.FILTER,
  payload: { table },
})
export const filterByFields = fields => ({
  type: Actions.FILTER,
  payload: { fields },
})
export const filter = data => ({
  type: Actions.FILTER,
  payload: data,
})

// Database Actions
export const setDatabaseInfo = data => ({
  type: Actions.SET_DATABASE_INFO,
  payload: data,
})
export const fetchDatabaseInfo = () => ({
  type: Actions.FETCH_DATABASE_INFO,
  payload: axios.get('/database'),
})
export const cancelDatabaseActivity = id => ({
  type: Actions.FETCH_DATABASE_INFO,
  payload: axios.get('/database/cancel-process/' + id),
})
export const terminateDatabaseActivity = id => ({
  type: Actions.FETCH_DATABASE_INFO,
  payload: axios.get('/database/terminate-process/' + id),
})

// Logs Actions
export const fetchLogs = () => ({
  type: Actions.FETCH_LOGS,
  payload: axios.get('/logs'),
})
export const fetchLog = id => ({
  type: Actions.FETCH_LOG,
  payload: axios.get('/logs/' + id),
})
export const deleteLogs = () => ({
  type: Actions.FETCH_LOGS,
  payload: axios.delete('/logs'),
})
export const addLog = log => ({
  type: Actions.ADD_LOG,
  payload: log,
})

// Schedules Actions
export const setSchedules = data => ({
  type: Actions.SET_SCHEDULES,
  payload: data,
})
export const fetchSchedules = () => ({
  type: Actions.FETCH_SCHEDULES,
  payload: axios.get('/schedules'),
})
export const stopSchedule = name => ({
  type: Actions.FETCH_SCHEDULES,
  payload: axios.post('/schedules/' + name + '/stop'),
})
export const startSchedule = name => ({
  type: Actions.FETCH_SCHEDULES,
  payload: axios.post('/schedules/' + name + '/start'),
})
export const runScheduleNow = name => ({
  type: Actions.FETCH_SCHEDULES,
  payload: axios.post('/schedules/' + name + '/now'),
})

// Collector Actions
export const setCollectorStatus = data => ({
  type: Actions.SET_COLLECTOR_STATUS,
  payload: data,
})
export const fetchCollectorStatus = () => ({
  type: Actions.FETCH_COLLECTOR_STATUS,
  payload: axios.get('/collector/status'),
})
export const enableCollector = () => ({
  type: Actions.FETCH_COLLECTOR_STATUS,
  payload: axios.post('/collector/enable'),
})
export const disableCollector = () => ({
  type: Actions.FETCH_COLLECTOR_STATUS,
  payload: axios.post('/collector/disable'),
})

// Views
export const createView = data => ({
  type: Actions.CREATE_VIEW,
  payload: axios.post('/views', {
    ...data,
    filter: reduxStore.default.getState().filters,
  }),
})
export const updateView = data => ({
  type: Actions.UPDATE_VIEW,
  payload: axios.put('/views/' + data.id, {
    ...data,
    filter: reduxStore.default.getState().filters,
  }),
})
export const fetchViews = () => ({
  type: Actions.FETCH_VIEWS,
  payload: axios.get('/views'),
})
export const setView = data => ({
  type: Actions.SET_VIEW,
  payload: data,
})
export const deleteView = id => ({
  type: Actions.DELETE_VIEW,
  payload: axios.delete('/views/' + id),
})

// Websockets
export const wsConnect = () => ({
  type: Actions.WS_CONNECT,
})
export const subscribeDatabase = () => ({
  type: Actions.SUBSCRIBE_DATABASE,
})
export const unsubscribeDatabase = () => ({
  type: Actions.UNSUBSCRIBE_DATABASE,
})
export const subscribeCollector = () => ({
  type: Actions.SUBSCRIBE_COLLECTOR,
})
export const unsubscribeCollector = () => ({
  type: Actions.UNSUBSCRIBE_COLLECTOR,
})
export const subscribeLogs = () => ({
  type: Actions.SUBSCRIBE_LOGS,
})
export const unsubscribeLogs = () => ({
  type: Actions.UNSUBSCRIBE_LOGS,
})
export const subscribeSchedules = () => ({
  type: Actions.SUBSCRIBE_SCHEDULES,
})
export const unsubscribeSchedules = () => ({
  type: Actions.UNSUBSCRIBE_SCHEDULES,
})
