import moment from 'moment'

var currentColor = 0
const colorPalette = [
  '#B8336A',
  '#726DA8',
  '#A0D2DB',
  '#C490D1',
  '#764134',
  '#48ACF0',
  '#F3C677',
  '#AFA060',
  '#7CAE7A',
  '#313715',
  '#ACC196',
  '#0CBABA',
  '#D62246',
  '#FF521B',
  '#E59500',
  '#7B9EA8',
  '#731DD8',
  '#FFD400',
  '#B27C66',
  '#468C98',
]

const getNextColor = () => {
  const color = colorPalette[currentColor]
  currentColor = (currentColor + 1) % colorPalette.length
  return color
}

const calculateDateFormat = samples => {
  const noOfSamples = samples.length
  if (noOfSamples === 0) return
  const start = new Date(samples[0].time).getTime() / 1000
  const end = new Date(samples[noOfSamples - 1].time).getTime() / 1000

  const diff = Math.abs(end - start)
  if (diff > 60 * 60 * 24 * 7) return '%d.%m.%Y'
  if (diff > 60 * 60) return '%d.%m.%Y %H:%M'
  if (diff > 0) return '%d.%m.%Y %H:%M:%S'
}

export const generateGraphData = (samples, samplesData, selectedFields) => {
  // prepare data for graph
  let modifiedSamplesData = samplesData
  let fieldsData = {}
  const selectedFieldsArray = selectedFields.map(field => field.name)
  for (const sample of samples) {
    const time = sample.time
    for (const field of Object.keys(sample)) {
      if (field === 'time') continue
      if (!selectedFieldsArray.includes(field)) continue
      if (!fieldsData[field]) {
        fieldsData[field] = { id: field, avg: [], min: [], max: [] }
      }
      const displayTime = moment(time).format('DD.MM.YYYY HH:mm:ss')
      fieldsData[field].avg.push({ x: displayTime, y: sample[field].avg })
      fieldsData[field].min.push({ x: displayTime, y: sample[field].min })
      fieldsData[field].max.push({ x: displayTime, y: sample[field].max })
    }
  }

  // Remove elements that are not in user selected filter
  for (const sampleData of modifiedSamplesData) {
    if (!Object.keys(fieldsData).includes(sampleData.name)) {
      modifiedSamplesData = modifiedSamplesData.filter(
        data => data.name !== sampleData.name
      )
    }
  }

  for (const field of Object.keys(fieldsData)) {
    var changedIndex = -1
    var exists = false
    for (const [index, sampleData] of modifiedSamplesData.entries()) {
      if (sampleData.name === field) {
        changedIndex = index
        exists = true
        break
      }
    }

    // Skip if there is no change
    if (exists) {
      const r = modifiedSamplesData[changedIndex].samples[0].data
      const l = r.length
      if (
        fieldsData[field].avg[0].x === r[0].x &&
        fieldsData[field].avg.length === l
      ) {
        continue
      }
    }

    var currentData = {}
    if (!exists) {
      currentData = {
        name: field,
        samples: [
          {
            id: field + '#avg',
            type: 'avg',
            display: true,
            data: fieldsData[field].avg,
            color: getNextColor(),
          },
          {
            id: field + '#min',
            type: 'min',
            display: false,
            data: fieldsData[field].min,
            color: getNextColor(),
          },
          {
            id: field + '#max',
            type: 'max',
            display: false,
            data: fieldsData[field].max,
            color: getNextColor(),
          },
        ],
      }
    } else {
      const oldSamples = modifiedSamplesData[changedIndex].samples
      currentData = {
        name: field,
        samples: [
          {
            ...oldSamples.filter(sample => sample.type === 'avg')[0],
            data: fieldsData[field].avg,
          },
          {
            ...oldSamples.filter(sample => sample.type === 'min')[0],
            data: fieldsData[field].min,
          },
          {
            ...oldSamples.filter(sample => sample.type === 'max')[0],
            data: fieldsData[field].max,
          },
        ],
      }
    }

    if (exists) {
      modifiedSamplesData = [
        ...modifiedSamplesData.slice(0, changedIndex),
        currentData,
        ...modifiedSamplesData.slice(changedIndex + 1),
      ]
    } else {
      modifiedSamplesData = [...modifiedSamplesData, currentData]
    }
  }
  const dateFormat = calculateDateFormat(samples)
  return { modifiedSamplesData, dateFormat }
}
