import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { deleteStation } from '../../redux/actions';

import { Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Input } from 'reactstrap';

const DeleteStation = (props) => {
    const { history, buttonLabel, style, className, station, deleteStation } = props;

    const [modal, setModal] = useState(false);
    const [typedName, setTypedName] = useState("");

    const toggle = () => {
        setModal(!modal);
    };

    const cancel = (event) => {
        event.preventDefault();
        toggle();
    }

    const handleChange = (event) => {
        setTypedName(event.target.value);
    }

    const handleDelete = (event) => {
        deleteStation(station.id);
    }

    useEffect(() => {
        if (station.deleted) {
            history.replace('/admin/stations');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [station.deleted]);

    return (
        <div>
            <Button color="danger" style={style} onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <div>
                    <ModalBody>
                        <h5>Are you sure?</h5>
                        <p className="text-danger">Unexpected bad things will happen if you don’t read this! </p>
                        <p>This action cannot be undone. This will permanently delete the <b>{station.alias}</b> station,
                        tables and data collected associated with the station. This will not delete the licence used for this station.</p>
                        <p>Please type <b>{station.alias}</b> to confirm</p>
                        <Form>
                            <FormGroup>
                                <Input
                                    type="text"
                                    id="alias"
                                    value={typedName}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={cancel}>Cancel</Button>
                        <Button color="danger" onClick={handleDelete} disabled={typedName !== station.alias}>Delete</Button>
                    </ModalFooter>
                </div>
            </Modal>
        </div>
    );
}


const mapStateToProps = (state /*, ownProps*/) => {
    return {
        station: state.stations.station,
    }
}

const mapDispatchToProps = {
    deleteStation
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeleteStation));