import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { fetchLogs, subscribeLogs, unsubscribeLogs } from 'redux/actions'
import { prettyDate } from 'helpers/intervals'

// reactstrap components
import { Badge, Card, CardBody, Table, Row, Col, Button } from 'reactstrap'

const Logs = props => {
  const { history, logs, fetchLogs, subscribeLogs, unsubscribeLogs } = props

  useEffect(() => {
    fetchLogs()
    subscribeLogs()
    return () => unsubscribeLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRowClick = logId => {
    history.push('/admin/log/' + logId)
  }

  const getBadge = type => {
    let color = 'default'
    if (type === 'info') color = 'info'
    else if (type === 'warning') color = 'warning'
    else if (type === 'error') color = 'danger'
    return <Badge color={color}>{type}</Badge>
  }

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card style={cardStyle}>
            <CardBody>
              <Table hover style={tableStyle}>
                <thead className="text-primary">
                  <tr>
                    <th>Timestamp</th>
                    <th>Trigger</th>
                    <th>Initiator</th>
                    <th>Description</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log, key) => (
                    <tr key={key}>
                      <td>{prettyDate(log.created_on)}</td>
                      <td>{log.trigger}</td>
                      <td>{log.initiator}</td>
                      <td>{log.description}</td>
                      <td>{getBadge(log.type)}</td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => handleRowClick(log.id)}>
                          Details
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const cardStyle = {
  overflow: 'auto',
}

const tableStyle = {
  whiteSpace: 'nowrap',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    logs: state.logs.all,
  }
}

const mapDispatchToProps = {
  fetchLogs,
  subscribeLogs,
  unsubscribeLogs,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logs))
