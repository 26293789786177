import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'

import 'bootstrap/dist/css/bootstrap.css'
import 'assets/scss/paper-dashboard.scss?v=1.1.0'
import 'assets/demo/demo.css'
//import "perfect-scrollbar/css/perfect-scrollbar.css";
import 'assets/css/theme.scss'

import App from './App'
import DataLoader from 'components/DataLoader/DataLoader'
import NotificationContainer from 'components/CustomUI/Notifications/NotificationContainer'

const history = createBrowserHistory()

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <DataLoader />
      <NotificationContainer />
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)
