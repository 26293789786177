import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import { isMaster, isAdmin } from 'helpers/roleHandler'
import { fetchUser, fetchProfile } from 'redux/actions'

// reactstrap components
import { Card, CardHeader, CardBody, Table, Row, Col, Badge } from 'reactstrap'

import DeleteUser from './DeleteUser'
import AddLicence from './AddLicence'
import CustomCheckbox from 'components/CustomUI/Checkbox'
import AddOrEditUser from 'components/AddOrEditUser/AddOrEditUser'

import EditLicence from 'components/EditLicence/EditLicence'
import { prettyDate } from 'helpers/intervals'

const User = props => {
  const { match, user, currentUser, fetchUser, fetchProfile } = props

  const fetchData = () => {
    if (match.params.id !== undefined) {
      fetchUser(match.params.id)
    } else {
      fetchProfile()
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="content">
      {(isAdmin() || currentUser.id === user.id) && (
        <div className="action-bar">
          <AddOrEditUser label="Edit" user={user} />
          {currentUser.id !== user.id && <DeleteUser label="Delete" />}
        </div>
      )}
      <Row>
        <Col md="6">
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>User Details</h6>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover>
                <tbody>
                  <tr>
                    <th scope="row">Name</th>
                    <td>
                      {user.verified_email ? (
                        `${user.first_name} ${user.last_name}`
                      ) : (
                        <i>Pending registration...</i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{user.email}</td>
                  </tr>
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <CustomCheckbox value={user.enabled} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Role</th>
                    <td>
                      <Badge
                        className={`role-badge-${user.role?.toLowerCase()}`}>
                        {user.role}
                      </Badge>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Last Login</th>
                    <td>{prettyDate(user.last_login)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Created On</th>
                    <td>{prettyDate(user.created_on)}</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        {isAdmin() && (
          <Col md="6">
            <Card style={cardStyle}>
              <CardHeader>
                <div style={{ textAlign: 'center' }}>
                  <h6>Licences</h6>
                </div>
              </CardHeader>
              <CardBody>
                <Table hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Station</th>
                      <th>Expiry Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!user.licences ? (
                      <tr></tr>
                    ) : (
                      user.licences.map((licence, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              {isMaster() ? (
                                <EditLicence
                                  label={licence.name}
                                  licence={licence}
                                  onUpdate={fetchData}
                                />
                              ) : (
                                licence.name
                              )}
                            </td>
                            <td>
                              {licence.station_name ? (
                                <Link
                                  to={'/admin/station/' + licence.station_id}>
                                  {licence.station_name}
                                </Link>
                              ) : (
                                <i>unassigned</i>
                              )}
                            </td>
                            <td>
                              {moment(licence.expiry_date).format('DD.MM.YYYY')}
                            </td>
                          </tr>
                        )
                      })
                    )}
                  </tbody>
                </Table>
                {isMaster() && (
                  <div style={{ textAlign: 'center' }}>
                    <AddLicence label="Add New Licence" />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  )
}

const cardStyle = {
  overflow: 'auto',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    user: state.users.user,
    currentUser: state.account.currentUser,
  }
}

const mapDispatchToProps = {
  fetchUser,
  fetchProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User))
