import React from 'react'
import { connect } from 'react-redux'
import { enableCollector, disableCollector } from 'redux/actions'

import { Button } from 'reactstrap'

const CollectorActionBar = props => {
  const { status, loading, enableCollector, disableCollector } = props

  const handleToggle = () => {
    if (status.enabled) {
      disableCollector()
    } else {
      enableCollector()
    }
  }

  return (
    <div style={{ display: 'inline-flex' }}>
      <Button
        disabled={loading}
        color={status.enabled ? 'danger' : 'success'}
        onClick={handleToggle}>
        {status.enabled ? 'Disable' : 'Enable'}
      </Button>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    status: state.collector.status,
    loading: state.loader.loading,
  }
}

const mapDispatchToProps = {
  enableCollector,
  disableCollector,
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectorActionBar)
