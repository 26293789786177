import React from "react";
import { Row } from "reactstrap";

const NotFound = (props) => {
  return (
    <div className="content">
      <Row></Row>
    </div>
  );
}

export default NotFound;
