import React from 'react'

import './styles.scss'

const Switch = props => {
  const { className, id, label, checked, onChange } = props
  return (
    <div className={'form-check form-switch ui-switch ' + className}>
      <input
        className="form-check-input"
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default Switch
