import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  Alert,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Card,
  Col,
  CardHeader,
  CardBody,
  Container,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle as successIcon,
  faExclamationCircle as errorIcon,
} from '@fortawesome/free-solid-svg-icons'

import { fetchInvitedUser, registerInvitedUser } from 'redux/actions'

import './styles.scss'

const Login = props => {
  const { invitedUser, fetchInvitedUser, registerInvitedUser } = props

  const [userData, setUserData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    passwordConfirmation: '',
  })

  const [validationError, setValidationError] = useState('')

  useEffect(() => {
    if (invitedUser.email)
      setUserData({
        ...userData,
        email: invitedUser.email,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitedUser])

  useEffect(() => {
    const { match } = props
    const token = match.params.token
    fetchInvitedUser(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = event => {
    setUserData({
      ...userData,
      [event.target.id]: event.target.value,
    })
  }

  function validateForm() {
    const {
      email,
      first_name,
      last_name,
      password,
      passwordConfirmation,
    } = userData
    let message
    if (first_name.length < 3) message = 'First name is too short'
    if (last_name.length < 3) message = 'Last name is too short'
    if (email.length < 4) message = 'Email address is not valid'
    if (password.length < 8)
      message = 'Password must be at least 8 characters long'
    if (password !== passwordConfirmation)
      message = 'Pasword confirmation does not match password'
    if (message) {
      setValidationError(message)
      return false
    }
    return true
  }

  function handleSubmit(event) {
    event.preventDefault()

    if (!validateForm()) {
      return
    }

    const { first_name, last_name, password } = userData
    const { match, history } = props
    const token = match.params.token

    registerInvitedUser({
      token,
      first_name,
      last_name,
      password,
    })

    setTimeout(() => {
      history.push('/login')
    }, 3000)
  }

  return (
    <div className="invitation-register">
      <div style={backgroundImageStyle}></div>
      <Container
        className="align-items-center wrapper"
        style={{ display: 'grid' }}>
        <Row>
          <Col md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <Card style={{ paddingBottom: 20 }}>
              <CardHeader>
                <div className="logo-img">
                  <img src="/logo/horizontal-light.svg" alt="deltacloud-logo" />
                </div>
              </CardHeader>
              <CardBody>
                {invitedUser.error || invitedUser.success ? (
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: 14,
                    }}>
                    {invitedUser.success && (
                      <div>
                        <FontAwesomeIcon
                          icon={successIcon}
                          size="3x"
                          style={{ marginBottom: 10, color: 'green' }}
                        />
                        <div>Registered successfuly</div>
                        <div>Redirecting to login page...</div>
                      </div>
                    )}
                    {invitedUser.error && (
                      <div>
                        <FontAwesomeIcon
                          icon={errorIcon}
                          size="3x"
                          style={{ marginBottom: 10, color: 'red' }}
                        />
                        <div>{invitedUser.error}</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        padding: '0 0 10px 0',
                        textAlign: 'center',
                      }}>
                      Please fill in your information to finish the registration
                      process
                    </div>
                    <Form>
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          autoFocus
                          id="email"
                          type="email"
                          value={userData.email}
                          onChange={handleChange}
                        />
                      </FormGroup>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormGroup className="me-1">
                          <Label>First Name</Label>
                          <Input
                            autoFocus
                            id="first_name"
                            type="text"
                            value={userData.first_name}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup className="ml-1">
                          <Label>Last Name</Label>
                          <Input
                            autoFocus
                            id="last_name"
                            type="text"
                            value={userData.last_name}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </div>
                      <FormGroup>
                        <Label>Password</Label>
                        <Input
                          id="password"
                          value={userData.password}
                          onChange={handleChange}
                          type="password"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Password Confirmation</Label>
                        <Input
                          id="passwordConfirmation"
                          value={userData.passwordConfirmation}
                          onChange={handleChange}
                          type="password"
                        />
                      </FormGroup>
                      {validationError && (
                        <Alert color="danger">{validationError}</Alert>
                      )}
                      <Button color="primary" block onClick={handleSubmit}>
                        Register
                      </Button>
                    </Form>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const backgroundImageStyle = {
  background: "url('/login-background.jpeg') no-repeat center center fixed",
  backgroundSize: 'cover',
  transform: 'scale(' + 1.1 + ')',
  filter: 'blur(' + 2 + 'px)',
  position: 'fixed',
  width: 100 + 'vw',
  height: 100 + 'vh',
  opacity: 0.6,
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    invitedUser: state.account.invitedUser,
  }
}

const mapDispatchToProps = {
  fetchInvitedUser,
  registerInvitedUser,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
