import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { filter } from '../../../redux/actions'
import { DateTimePicker } from 'react-widgets'
import { Input, Label, Table } from 'reactstrap'
import { getValidIntervalArray } from '../../../helpers/intervals'
import ActionDropdown from './ActionDropdown'

const Filter = props => {
  const {
    selectedInterval,
    selectedStartTime,
    selectedEndTime,
    selectedPeriod,
    samplesInfo,
    filter,
  } = props

  const handleChange = event => {
    filter({ [event.target.id]: event.target.value })
  }

  const dataIntervals = [
    { label: 'Last hour', value: '1h' },
    { label: 'Last 4 hours', value: '4h' },
    { label: 'Last 12 hours', value: '12h' },
    { label: 'Last day', value: '1d' },
    { label: 'Last week', value: '1w' },
    { label: 'Last month', value: '1M' },
    { label: 'Last 3 months', value: '3M' },
    { label: 'Last year', value: '1Y' },
    { label: 'Custom', value: 'custom' },
  ]

  const getIntervalObject = value => {
    for (const interval of dataIntervals) {
      if (interval.value === value) return interval.value
    }
    return undefined
  }

  const [periodIntervals, setPeriodIntervals] = useState([])

  useEffect(() => {
    if (samplesInfo.min_interval === undefined) {
      setPeriodIntervals([])
      return
    }
    setPeriodIntervals(getValidIntervalArray())
  }, [samplesInfo])

  return (
    <div className="dataQuery-body-options">
      <Table
        size="sm"
        borderless
        className="time-options"
        style={{ margin: 0 }}>
        <tbody>
          <tr>
            <td style={{ minWidth: 'unset', width: 40 + 'px' }}>
              <Label style={{ color: 'transparent' }}>Actions</Label>
              <br />
              <ActionDropdown />
            </td>
            <td>
              <Label>Data Interval</Label>
              <Input
                type="select"
                id="interval"
                value={getIntervalObject(selectedInterval)}
                onChange={handleChange}>
                {dataIntervals.map(interval => (
                  <option key={interval.value} value={interval.value}>
                    {interval.label}
                  </option>
                ))}
              </Input>
            </td>
            <td>
              <Label for="filterFrom">Start Time</Label>
              <DateTimePicker
                format="DD.MM.YYYY HH:mm"
                value={new Date(selectedStartTime)}
                onChange={value =>
                  handleChange({
                    target: { id: 'start_time', value: value.toISOString() },
                  })
                }
              />
            </td>
            <td>
              <Label for="filterFrom">End Time</Label>
              <DateTimePicker
                format="DD.MM.YYYY HH:mm"
                value={new Date(selectedEndTime)}
                onChange={value =>
                  handleChange({
                    target: { id: 'end_time', value: value.toISOString() },
                  })
                }
              />
            </td>
            <td>
              <Label>Sample Period</Label>
              <Input
                type="select"
                id="period"
                value={selectedPeriod}
                onChange={handleChange}>
                {periodIntervals.map(interval => (
                  <option key={interval} value={interval}>
                    {interval}
                  </option>
                ))}
              </Input>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    selectedInterval: state.filters.interval,
    selectedStartTime: state.filters.start_time,
    selectedEndTime: state.filters.end_time,
    selectedPeriod: state.filters.period,
    samplesInfo: state.samples.info,
  }
}

const mapDispatchToProps = {
  filter,
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
