import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { cancelDatabaseActivity, terminateDatabaseActivity, fetchDatabaseInfo, subscribeDatabase, unsubscribeDatabase } from '../../../redux/actions';

import moment from "moment";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col
} from "reactstrap";

import DatabaseActionBar from "./DatabaseActionBar";

const Database = (props) => {
  const { activities, systemTables, dataTables, fetchDatabaseInfo, subscribeDatabase, unsubscribeDatabase } = props;

  useEffect(() => {
    fetchDatabaseInfo();
    subscribeDatabase()
    return () => unsubscribeDatabase()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <DatabaseActionBar />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card style={cardStyle}>
            <CardBody>
              <Table hover style={tableStyle}>
                <thead className="text-primary">
                  <tr>
                    <th>PID</th>
                    <th>Username</th>
                    <th>Client Address</th>
                    <th>Start Time</th>
                    <th>Wait Event Type</th>
                    <th>Wait Event</th>
                    <th>State</th>
                    <th>Query</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {activities && activities.map((activity, key) => {
                    return (
                      <tr key={key}>
                        <td>{activity.pid}</td>
                        <td>{activity.usename}</td>
                        <td>{activity.client_addr}</td>
                        <td>{moment(activity.backend_start).format('DD.MM.YYYY HH:mm:ss')}</td>
                        <td>{activity.wait_event_type}</td>
                        <td>{activity.wait_event}</td>
                        <td>{activity.state}</td>
                        <td>{activity.query}</td>
                        <td style={{ display: 'inline-flex' }}>
                          <Button
                            onClick={() => cancelDatabaseActivity(activity.pid)}
                            color="warning">
                            Cancel
                          </Button>
                          <Button
                            style={{ marginLeft: 5 + 'px' }}
                            onClick={() => terminateDatabaseActivity(activity.pid)}
                            color="danger">
                            Terminate
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>System Table Sizes</h6>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover>
                <thead className="text-primary">
                  <tr>
                    <th>Name</th>
                    <th>Size</th>
                  </tr>
                </thead>
                <tbody>
                  {systemTables && systemTables.map((table, index) =>
                    <tr key={index}>
                      <td>{table.table_name}</td>
                      <td>{table.size}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>Data Table Sizes</h6>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover>
                <thead className="text-primary">
                  <tr>
                    <th>Name</th>
                    <th>Size</th>
                  </tr>
                </thead>
                <tbody>
                  {dataTables && dataTables.map((table, index) =>
                    <tr key={index}>
                      <td>{table.table_name}</td>
                      <td>{table.size}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const cardStyle = {
  overflow: 'auto',
}

const tableStyle = {
  whiteSpace: 'nowrap'
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    activities: state.database.info.activities,
    systemTables: state.database.info.systemTables,
    dataTables: state.database.info.dataTables,
  }
}

const mapDispatchToProps = {
  cancelDatabaseActivity, fetchDatabaseInfo, subscribeDatabase, unsubscribeDatabase
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Database);
