import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Card,
  Col,
  CardHeader,
  CardBody,
  Container,
} from 'reactstrap'
import { login } from 'redux/actions'

import './styles.scss'

const Login = props => {
  const { login } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  function validateForm() {
    return email.length > 0 && password.length > 0
  }

  function handleSubmit(event) {
    event.preventDefault()
    login(email, password)
  }
  return (
    <div className="login">
      <div style={backgroundImageStyle}></div>
      <Container
        className="align-items-center wrapper"
        style={{ display: 'grid' }}>
        <Row>
          <Col md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
            <Card>
              <CardHeader>
                <div className="logo-img">
                  <img src="/logo/horizontal-light.svg" alt="deltacloud-logo" />
                </div>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      autoFocus
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                    />
                  </FormGroup>
                  <Button
                    className="mt-4"
                    color="primary"
                    block
                    disabled={!validateForm()}
                    onClick={handleSubmit}>
                    Login
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const backgroundImageStyle = {
  background: "url('/login-background.jpeg') no-repeat center center fixed",
  backgroundSize: 'cover',
  transform: 'scale(' + 1.1 + ')',
  filter: 'blur(' + 2 + 'px)',
  position: 'fixed',
  width: 100 + 'vw',
  height: 100 + 'vh',
  opacity: 0.6,
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    samples: state.samples.all,
    filters: state.filters,
  }
}

const mapDispatchToProps = {
  login,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
