import React from 'react'
import { connect } from 'react-redux'
import { boolToColor } from 'helpers/colors'
import Dot from 'components/CustomUI/Dot'

import './styles.scss'

const Footer = props => {
  const { wsConnected } = props
  return (
    <div className="footer">
      <div className="copyright px-3">
        <Dot color={boolToColor(wsConnected)} />
        ver {process.env.REACT_APP_VERSION}
      </div>
      <div className="copyright ml-auto px-3">
        Delta Tech &copy; {1900 + new Date().getYear()}
      </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    wsConnected: state.loader.wsConnected,
  }
}

export default connect(mapStateToProps, null)(Footer)
