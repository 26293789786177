import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createView, updateView } from 'redux/actions'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

const AddOrEditView = props => {
  const { children, className, disabled, view, createView, updateView } = props

  const [modal, setModal] = useState(false)
  const [viewData, setViewData] = useState({
    name: 'New View',
  })

  useEffect(() => {
    if (view?.id) {
      setViewData({
        id: view.id,
        name: view.name,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  const toggle = () => {
    setModal(!modal)
  }

  const showModal = () => {
    if (!disabled) setModal(true)
  }

  const handleChange = event => {
    setViewData({
      ...viewData,
      [event.target.id]: event.target.value,
    })
  }

  const handleCancel = event => {
    event.preventDefault()
    setModal(false)
  }

  const handleSave = event => {
    event.preventDefault()
    if (view?.id) updateView(viewData)
    else {
      createView(viewData)
    }
    setModal(false)
  }

  return (
    <div onClick={showModal}>
      {children}
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <div>
          <ModalBody>
            {view?.id ? (
              <>
                <h5>Edit View</h5>
                <p>
                  Editing view <b>{view.name}</b>
                </p>
              </>
            ) : (
              <>
                <h5>Save View</h5>
                <p>Saved view can be easly accessed later.</p>
              </>
            )}
            <Form>
              <FormGroup>
                <Label for="stationAlias">Name:</Label>
                <Input
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={viewData.name}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    // user: state.users.user,
  }
}

const mapDispatchToProps = {
  createView,
  updateView,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditView)
