import React from 'react'
import { Navbar } from 'reactstrap'
import logo from 'assets/images/full-light.svg'
import UserDropdown from './UserDropdown'

import './styles.scss'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: 'transparent',
    }
    this.toggle = this.toggle.bind(this)
    this.dropdownToggle = this.dropdownToggle.bind(this)
    this.sidebarToggle = React.createRef()
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      })
    } else {
      this.setState({
        color: 'dark',
      })
    }
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }
  openSidebar() {
    document.documentElement.classList.toggle('nav-open')
    this.sidebarToggle.current.classList.toggle('toggled')
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: 'dark',
      })
    } else {
      this.setState({
        color: 'transparent',
      })
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateColor.bind(this))
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
      this.sidebarToggle.current.classList.toggle('toggled')
    }
  }
  render() {
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color="dark"
        expand="lg"
        className="navbar-absolute fixed-top navbar-transparent">
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={this.sidebarToggle}
              className="navbar-toggler"
              onClick={() => this.openSidebar()}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <div> </div>
          <div className="navbar-logo">
            <img height="30px" src={logo} alt="deltacloud-logo" />
          </div>
          <UserDropdown />
        </div>
      </Navbar>
    )
  }
}

export default Header
