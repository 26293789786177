import React, { useEffect } from 'react'
import { Toast, ToastHeader, ToastBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle as infoIcon,
  faCheckCircle as successIcon,
  faExclamationCircle as errorIcon,
} from '@fortawesome/free-solid-svg-icons'
import { colors } from 'helpers/colors'

const Notification = props => {
  const { data, onClose } = props
  const { id, type = 'info', title, message } = data || {}

  useEffect(() => {
    setTimeout(handleClose, 5000)
    // eslint-disable-next-line
  }, [])

  const handleClose = () => {
    onClose(id)
  }

  const icons = {
    info: { icon: infoIcon, color: colors.DARK_GRAY },
    success: { icon: successIcon, color: colors.GREEN },
    error: { icon: errorIcon, color: colors.RED },
  }

  const iconData = icons[type]

  return (
    <Toast isOpen={true}>
      <ToastHeader
        toggle={handleClose}
        icon={
          <FontAwesomeIcon
            icon={iconData.icon}
            color={iconData.color}
            size="lg"
          />
        }>
        {title}
      </ToastHeader>
      {message && <ToastBody>{message}</ToastBody>}
    </Toast>
  )
}

export default Notification
