const moment = require('moment')
const MAX_DATA = 400

const units = {
  s: 1,
  m: 60,
  h: 3600,
  d: 86400,
  w: 604800,
  M: 2592000,
}

const defaultIntervals = [
  '1s',
  '5s',
  '10s',
  '1m',
  '5m',
  '10m',
  '30m',
  '1h',
  '2h',
  '4h',
  '6h',
  '12h',
  '1d',
  '3d',
  '1w',
  '2w',
  '1M',
]

function getValidIntervalArray() {
  const store = require('../redux/store').default
  const sampleInfo = store.getState().samples.info
  if (
    !sampleInfo.first_timestamp ||
    !sampleInfo.last_timestamp ||
    sampleInfo.min_interval === undefined
  )
    return []

  const firstDateInSeconds =
    new Date(sampleInfo.first_timestamp).getTime() / 1000
  const lastDateInSeconds = new Date(sampleInfo.last_timestamp).getTime() / 1000
  const diff = Math.abs(firstDateInSeconds - lastDateInSeconds)
  const max_interval = diff / 2

  const min_interval = Math.max(diff / MAX_DATA, sampleInfo.min_interval)

  return defaultIntervals.filter(interval => {
    const currentIntervalInSeconds = intervalToSeconds(interval)
    if (
      currentIntervalInSeconds >= min_interval &&
      currentIntervalInSeconds <= max_interval
    )
      return true
    return false
  })
}

function intervalToSeconds(string) {
  if (!string) return

  var index = string.search(/[A-Za-z]/)
  const multiplier = Number(string.substring(0, index))
  const unit = string.substring(index)

  return multiplier * units[unit]
}

function secondsToInterval(seconds) {
  var lastUnit = 's'
  for (const unit of Object.keys(units)) {
    if (units[unit] > seconds) break
    lastUnit = unit
  }

  const multiplier = seconds / units[lastUnit]
  return multiplier + lastUnit
}

function intervalToDate(string) {
  if (!string) return

  var index = string.search(/[A-Za-z]/)
  const number = Number(string.substring(0, index))
  const interval = string.substring(index)
  return moment()
    .subtract(number, interval)
    .toDate()
}

const prettyDate = date => {
  if (date) return moment(date).format('DD.MM.YYYY HH:mm:ss')
  return ''
}

module.exports = {
  intervalToSeconds,
  intervalToDate,
  secondsToInterval,
  getValidIntervalArray,
  prettyDate,
}
