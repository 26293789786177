import React, { useState } from "react";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { testDataloggerConnection, clearTestData } from '../../redux/actions';

import { Alert, Button, Modal, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';

const DeleteStation = (props) => {
    const { buttonLabel, style, className, station, testConnectionResponse, testDataloggerConnection, clearTestData } = props;

    const [modal, setModal] = useState(false);
    const [stationValues, setStationValues] = useState({});

    const toggle = () => {
        if (modal) {
            clearTestData();
        } else {
            setStationValues(station);
        }
        setModal(!modal);
    };

    const cancel = (event) => {
        event.preventDefault();
        toggle();
    }

    const handleChange = (event) => {
        setStationValues({
            ...stationValues,
            [event.target.id]: event.target.value,
        });
    }

    const handleTestConnection = (event) => {
        event.preventDefault();
        testDataloggerConnection(stationValues.ip_address, stationValues.extra_response_time, stationValues.security_code);
    }

    return (
        <>
            <Button color="primary" style={style} onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <div>
                    <ModalBody>
                        <h6>{station.alias} - Testing Connection</h6>
                        {testConnectionResponse.outcome !== undefined &&
                            <Alert color={testConnectionResponse.outcome === 1 ? "success" : "danger"}>
                                <h6>{testConnectionResponse.description}</h6>

                                {testConnectionResponse.error_description ?
                                    <div>Server reponse: <b>{testConnectionResponse.error_description}</b></div> :
                                    <div>Datalogger code: <b>{testConnectionResponse.outcome}</b></div>
                                }
                                <div>Response Time: <b>{(testConnectionResponse.response_time / 1000)?.toFixed(1)}</b> seconds</div>
                            </Alert>
                        }
                        <Form>
                            <FormGroup>
                                <Label for="stationIP">IP Address</Label>
                                <Input
                                    type="text"
                                    id="ip_address"
                                    placeholder="e.g. 100.20.20.5:1234"
                                    value={stationValues.ip_address}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="securityCode">Security Code</Label>
                                <Input
                                    type="text"
                                    id="security_code"
                                    placeholder="None"
                                    value={stationValues.security_code || ""}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="extra_response_time">Extra Response Time (seconds)</Label>
                                <Input
                                    type="number"
                                    id="extra_response_time"
                                    placeholder="e.g. 5"
                                    value={stationValues.extra_response_time}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={cancel}>Cancel</Button>
                        <Button color="primary" onClick={handleTestConnection}>Test Connection</Button>
                    </ModalFooter>
                </div>
            </Modal>
        </>
    );
}


const mapStateToProps = (state /*, ownProps*/) => {
    return {
        testConnectionResponse: state.dataloggers.testConnectionResponse,
        station: state.stations.station,
    }
}

const mapDispatchToProps = {
    testDataloggerConnection, clearTestData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeleteStation));