import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import logger from 'redux-logger'

import rootReducer from './reducers'
import errorMiddleware from './errorMiddleware'
import socketMiddleware from './socketMiddleware'

const middlewares = [errorMiddleware, promise, thunk, socketMiddleware]
if (process.env.NODE_ENV === 'development' && false) {
  middlewares.push(logger)
}

export default createStore(rootReducer, applyMiddleware(...middlewares))
