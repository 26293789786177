import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBolt as actionIcon,
  faCloudDownloadAlt as exportIcon,
  faCog as settingsIcon,
} from '@fortawesome/free-solid-svg-icons'
import ExportCSV from './ExportCSV'
import Settings from './Settings'

const ActionDropdown = props => {
  const { samples = [] } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown
      className="action-button"
      isOpen={dropdownOpen}
      toggle={toggle}
      direction="down"
      inNavbar={true}>
      <DropdownToggle color="primary" style={actionButtonStyle}>
        <FontAwesomeIcon icon={actionIcon} size="lg" />
      </DropdownToggle>
      <DropdownMenu>
        <Settings>
          <DropdownItem>
            <FontAwesomeIcon icon={settingsIcon} style={itemIcon} size="lg" />{' '}
            Settings
          </DropdownItem>
        </Settings>
        <ExportCSV disabled={samples.length === 0}>
          <DropdownItem disabled={samples.length === 0}>
            <FontAwesomeIcon icon={exportIcon} style={itemIcon} size="lg" />{' '}
            ExportCSV
          </DropdownItem>
        </ExportCSV>
      </DropdownMenu>
    </Dropdown>
  )
}

const actionButtonStyle = {
  height: 26 + 'px',
  paddingTop: 7 + 'px',
  paddingBottom: 7 + 'px',
}

const itemIcon = {
  width: '20px',
  marginRight: '3px',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    samples: state.samples.all,
  }
}

const mapDispatchToProps = {
  //fetchSamplesCSV
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionDropdown)
