import * as Actions from '../actionTypes'

const initialState = {
  all: [],
  user: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_USERS.FULFILLED():
    case Actions.INVITE_USER.FULFILLED():
    case Actions.CREATE_USER.FULFILLED(): {
      return {
        ...state,
        all: action.payload.data,
        user: {},
      }
    }
    case Actions.FETCH_USER.FULFILLED(): {
      return {
        ...state,
        user: action.payload.data,
      }
    }
    case Actions.DELETE_USER.FULFILLED(): {
      return {
        ...state,
        user: { ...state.user, deleted: true },
      }
    }
    default:
      return state
  }
}
