import React, { useState } from 'react'
import { connect } from 'react-redux'
import { createUser, updateUser } from '../../redux/actions'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

import { generateString } from '../../helpers/stringGenerator'

const AddOrEditUser = props => {
  const { label, user, className, createUser, updateUser } = props

  const [modal, setModal] = useState(false)

  const initialUserValues = {
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    role: 'Admin',
    noOfLicences: 0,
  }

  const [userValues, setUserValues] = useState(initialUserValues)

  const handleChange = event => {
    setUserValues({
      ...userValues,
      [event.target.id]: event.target.value,
    })
  }

  const toggle = () => {
    if (!modal) {
      setUserValues({
        ...initialUserValues,
        ...user,
        first_name: user.first_name || '',
        last_name: user.last_name || '',
      })
    }
    setModal(!modal)
  }

  const saveNewUser = event => {
    event.preventDefault()
    if (user) {
      updateUser(userValues)
    } else {
      createUser(userValues)
    }
    toggle()
  }

  const generatePassword = () => {
    setUserValues({
      ...userValues,
      password: generateString(16),
    })
  }

  return (
    <div>
      <Button color="primary" onClick={toggle}>
        {label}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input
                type="text"
                id="first_name"
                value={userValues.first_name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input
                type="text"
                id="last_name"
                value={userValues.last_name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="text"
                id="email"
                value={userValues.email}
                onChange={handleChange}
              />
            </FormGroup>
            {!user && (
              <FormGroup inline>
                <Label for="password">Password</Label>
                <Input
                  type="text"
                  id="password"
                  value={userValues.password}
                  onChange={handleChange}
                />
                <Button onClick={generatePassword}>
                  Generate Strong Password
                </Button>
              </FormGroup>
            )}
            {!user && (
              <FormGroup>
                <Label for="role">Role</Label>
                <Input
                  type="select"
                  id="role"
                  value={userValues.role}
                  onChange={handleChange}>
                  <option>User</option>
                  <option>Admin</option>
                  <option>Master</option>
                </Input>
              </FormGroup>
            )}
            {!user && (
              <FormGroup>
                <Label for="noOfLicences">Number of Licences</Label>
                <Input
                  type="number"
                  id="noOfLicences"
                  value={userValues.noOfLicences}
                  onChange={handleChange}></Input>
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={saveNewUser}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = {
  createUser,
  updateUser,
}

export default connect(null, mapDispatchToProps)(AddOrEditUser)
