import React, { useState } from 'react'
import { connect } from 'react-redux'
import roleHandler from 'helpers/roleHandler'
import { cancelDatabaseActivity, fetchDatabaseInfo } from '../../redux/actions'
import classnames from 'classnames'

// reactstrap components
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap'

import Database from './Database/Database'
import Collector from './Collector/Collector'
import Schedules from './Schedules'
import Logs from './Logs'

const Administration = props => {
  const [activeTab, setActiveTab] = useState('1')

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  if (!roleHandler.isMaster()) return ''

  return (
    <div className="content">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1')
            }}>
            Database
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2')
            }}>
            Collector
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => {
              toggle('3')
            }}>
            Schedules
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => {
              toggle('4')
            }}>
            Logs
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <Database />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <Collector />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <Schedules />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
              <Logs />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    activities: state.database.info.activities,
    systemTables: state.database.info.systemTables,
    dataTables: state.database.info.dataTables,
  }
}

const mapDispatchToProps = {
  cancelDatabaseActivity,
  fetchDatabaseInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(Administration)
