import React, { useState } from 'react'
import { connect } from 'react-redux'
import { updateLicence } from '../../redux/actions'

import { Link } from 'react-router-dom'
import { DateTimePicker } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

const EditLicence = props => {
  const { label, licence, onUpdate, className, updateLicence } = props

  const [modal, setModal] = useState(false)

  const [licenceValues, setLicenceValues] = useState(licence)

  const handleChange = event => {
    setLicenceValues({
      ...licenceValues,
      [event.target.id]: event.target.value,
    })
  }

  const toggle = () => {
    if (!modal) {
      setLicenceValues(licence)
    }
    setModal(!modal)
  }

  const saveLicence = event => {
    event.preventDefault()
    updateLicence(licenceValues).then(() => {
      onUpdate()
    })
    toggle()
  }

  const addMonth = () => {
    var currentDate = new Date(licenceValues.expiry_date)
    var newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1))
    setLicenceValues({
      ...licenceValues,
      expiry_date: newDate,
    })
  }

  const addYear = () => {
    var currentDate = new Date(licenceValues.expiry_date)
    var newDate = new Date(
      currentDate.setFullYear(currentDate.getFullYear() + 1)
    )
    setLicenceValues({
      ...licenceValues,
      expiry_date: newDate,
    })
  }

  return (
    <div>
      <Link to={'#'} onClick={toggle}>
        {label}
      </Link>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="firstName">Name</Label>
              <Input
                disabled
                type="text"
                id="name"
                value={licenceValues.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Station</Label>
              <Input
                disabled
                type="text"
                id="station"
                value={
                  licenceValues.station_name
                    ? licenceValues.station_name
                    : 'unassigned'
                }
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="collectBaseDate">Expiry Date</Label>
              <DateTimePicker
                format="DD.MM.YYYY"
                value={new Date(licenceValues.expiry_date)}
                onChange={value =>
                  handleChange({ target: { id: 'expiry_date', value: value } })
                }
                time={false}
              />
            </FormGroup>
            <Button onClick={addMonth}>+1 Month</Button>
            <Button onClick={addYear}>+1 Year</Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={saveLicence}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = {
  updateLicence,
}

export default connect(null, mapDispatchToProps)(EditLicence)
