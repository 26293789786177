// eslint-disable-next-line no-extend-native
String.prototype.FULFILLED = function() {
  return this + '_FULFILLED'
}
// eslint-disable-next-line no-extend-native
String.prototype.REJECTED = function() {
  return this + '_REJECTED'
}

// Websockets
export const WS_CONNECT = 'WS_CONNECT'
export const SUBSCRIBE_DATABASE = 'SUBSCRIBE_DATABASE'
export const UNSUBSCRIBE_DATABASE = 'UNSUBSCRIBE_DATABASE'
export const SUBSCRIBE_COLLECTOR = 'SUBSCRIBE_COLLECTOR'
export const UNSUBSCRIBE_COLLECTOR = 'UNSUBSCRIBE_COLLECTOR'
export const SUBSCRIBE_LOGS = 'SUBSCRIBE_LOGS'
export const UNSUBSCRIBE_LOGS = 'UNSUBSCRIBE_LOGS'
export const SUBSCRIBE_SCHEDULES = 'SUBSCRIBE_SCHEDULES'
export const UNSUBSCRIBE_SCHEDULES = 'UNSUBSCRIBE_SCHEDULES'

// Loader
export const SET_LOADER_STATE = 'SET_LOADER_STATE'

export const LOGIN = 'LOGIN'
export const RELOGIN = 'RELOGIN'
export const LOGOUT = 'LOGOUT'

export const INVITE_USER = 'INVITE_USER'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USERS = 'FETCH_USERS'
export const DELETE_USER = 'DELETE_USER'

export const FETCH_INVITED_USER = 'FETCH_INVITED_USER'
export const REGISTER_INVITED_USER = 'REGISTER_INVITED_USER'

export const FETCH_STATIONS = 'FETCH_STATIONS'
export const FETCH_STATION = 'FETCH_STATION'
export const CREATE_STATION = 'CREATE_STATION'
export const DELETE_STATION = 'DELETE_STATION'

export const TEST_DATALOGGER_CONNECTION = 'TEST_DATALOGGER_CONNECTION'
export const CLEAR_TEST_DATA = 'CLEAR_TEST_DATA'
export const FETCH_DATALOGGER_TABLES = 'FETCH_DATALOGGER_TABLES'

export const FETCH_TABLES = 'FETCH_TABLES'
export const FETCH_FIELDS = 'FETCH_FIELDS'

export const FETCH_SAMPLES = 'FETCH_SAMPLES'
export const FETCH_SAMPLES_INFO = 'FETCH_SAMPLES_INFO'
export const FETCH_LASTRECORD = 'FETCH_LASTRECORD'
export const CLEAR_SAMPLES = 'CLEAR_SAMPLES'
export const FETCH_SAMPLES_CSV = 'FETCH_SAMPLES_CSV'

export const FILTER = 'FILTER'

export const SET_DATABASE_INFO = 'SET_DATABASE_INFO'
export const FETCH_DATABASE_INFO = 'FETCH_DATABASE_INFO'

export const FETCH_LOGS = 'FETCH_LOGS'
export const FETCH_LOG = 'FETCH_LOG'
export const ADD_LOG = 'ADD_LOG'

export const SET_SCHEDULES = 'SET_SCHEDULES'
export const FETCH_SCHEDULES = 'FETCH_SCHEDULES'

export const FETCH_LICENCES = 'FETCH_LICENCES'
export const FETCH_LICENCE = 'FETCH_LICENCE'
export const CREATE_LICENCE = 'CREATE_LICENCE'
export const UPDATE_LICENCE = 'UPDATE_LICENCE'

export const SET_COLLECTOR_STATUS = 'SET_COLLECTOR_STATUS'
export const FETCH_COLLECTOR_STATUS = 'FETCH_COLLECTOR_STATUS'

export const CREATE_VIEW = 'CREATE_VIEW'
export const UPDATE_VIEW = 'UPDATE_VIEW'
export const FETCH_VIEW = 'FETCH_VIEW'
export const FETCH_VIEWS = 'FETCH_VIEWS'
export const SET_VIEW = 'SET_VIEW'
export const DELETE_VIEW = 'DELETE_VIEW'
