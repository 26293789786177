import React from "react";
import { connect } from 'react-redux';
import moment from "moment";

const NoDataNotification = (props) => {
    const { samplesInfo, showHint, children } = props;
    return (
        <div className="text-center" style={{ marginTop: 20 + 'vh' }}>
            <p>Nothing to show. Please select one of the sources and filters.</p>
            {!children && showHint && samplesInfo.first_ever_timestamp &&
                <p>Hint: found data from <b>{moment(samplesInfo.first_ever_timestamp).format('DD.MM.YYYY')}</b>
                    {" to "}
                    <b>{moment(samplesInfo.last_ever_timestamp).format('DD.MM.YYYY')}</b></p>
            }
            <p>{children}</p>
        </div>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        samplesInfo: state.samples.info,
    }
}

export default connect(
    mapStateToProps,
    null
)(NoDataNotification);