import React, { useEffect, useState } from 'react'
import Notification from './Notification'
import eventBus from './eventBus'

import './styles.scss'

const NotificationContainer = props => {
  const [notifications, setNotifications] = useState([])
  useEffect(() => {
    eventBus.on('createNotification', data => addNotification(data))
    return () => eventBus.remove('createNotification')
  }, [])

  const addNotification = data => {
    setNotifications(prev => [...prev, { id: Math.random(), ...data }])
  }

  const handleClose = id => {
    setNotifications(prev =>
      prev.filter(notification => notification.id !== id)
    )
  }

  return (
    <div className="notifications-container" id="notifications-container">
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          data={notification}
          onClose={handleClose}
        />
      ))}
    </div>
  )
}

export default NotificationContainer
