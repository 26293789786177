import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchViews, setView } from 'redux/actions'
import {
  Form,
  FormGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisV as actionIcon,
  faSave as saveAsIcon,
  faPen as editIcon,
  faTrashAlt as deleteIcon,
} from '@fortawesome/free-solid-svg-icons'
import { faSave as saveIcon } from '@fortawesome/free-regular-svg-icons'
import AddOrEditView from './AddOrEditViewModal'
import DeleteView from './DeleteViewModal'
import OverwriteView from './OverwriteViewModal'

const View = props => {
  const { views, selectedView, setView, fetchViews } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  useEffect(() => {
    fetchViews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleViewChange = event => {
    const viewId = Number(event.target.value)
    const newView = views.filter(view => view.id === viewId)[0]
    setView(newView || {})
  }

  return (
    <Form style={{ display: 'flex', marginBottom: '5px' }}>
      <FormGroup>
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          direction="down"
          inNavbar={true}>
          <DropdownToggle color="primary" style={actionButtonStyle}>
            <FontAwesomeIcon icon={actionIcon} size="lg" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header style={{ fontSize: '0.65rem' }}>
              View Actions
            </DropdownItem>
            <OverwriteView disabled={!selectedView.id}>
              <DropdownItem disabled={!selectedView.id}>
                <FontAwesomeIcon icon={saveIcon} size="lg" style={itemIcon} />{' '}
                Overwrite View
              </DropdownItem>
            </OverwriteView>
            <AddOrEditView>
              <DropdownItem>
                <FontAwesomeIcon icon={saveAsIcon} size="lg" style={itemIcon} />{' '}
                Save As New View
              </DropdownItem>
            </AddOrEditView>
            <AddOrEditView disabled={!selectedView.id} view={selectedView}>
              <DropdownItem disabled={!selectedView.id}>
                <FontAwesomeIcon icon={editIcon} size="lg" style={itemIcon} />{' '}
                Edit This View
              </DropdownItem>
            </AddOrEditView>
            <DropdownItem divider />
            <DeleteView disabled={!selectedView.id}>
              <DropdownItem disabled={!selectedView.id}>
                <FontAwesomeIcon icon={deleteIcon} size="lg" style={itemIcon} />{' '}
                Delete View
              </DropdownItem>
            </DeleteView>
          </DropdownMenu>
        </Dropdown>
      </FormGroup>
      <FormGroup style={{ flexGrow: 1 }}>
        <Input
          type="select"
          value={selectedView.id}
          onChange={handleViewChange}>
          <option value={0}>--New View--</option>
          {views.map(view => (
            <option key={view.id} value={Number(view.id)}>
              {view.name}
            </option>
          ))}
        </Input>
      </FormGroup>
    </Form>
  )
}

const actionButtonStyle = {
  height: 31 + 'px',
  marginTop: '1px',
  marginRight: '5px',
  marginBottom: 0,
}

const itemIcon = {
  width: '20px',
  marginRight: '3px',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    views: state.views.all,
    selectedView: state.views.view,
  }
}

const mapDispatchToProps = {
  fetchViews,
  setView,
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
