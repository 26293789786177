function pad(num) {
    return ("0" + num).slice(-2);
}

function secondsToObject(seconds) {
    var minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    var hours = Math.floor(minutes / 60)
    minutes = minutes % 60;
    var days = Math.floor(hours / 24)
    hours = hours % 24;

    var print = days ? pad(days) + "d " : "";
    print += hours ? pad(hours) + "h " : "";
    print += minutes ? pad(minutes) + "m " : "";
    print += seconds ? pad(seconds) + "s " : "";

    return {
        days, hours, minutes, seconds, print
    }
}

function objectToSeconds(obj) {
    return Number(obj.days) * 86400 + Number(obj.hours) * 3600 + Number(obj.minutes) * 60 + Number(obj.seconds);
}

export { secondsToObject, objectToSeconds }