import React from "react";
import { connect } from 'react-redux';
import moment from "moment";

import { Table } from "reactstrap";

import NoDataNotification from "components/NoDataNotification/NoDataNotification";
import Filter from "../FilterBar/FilterBar";

const Tables = (props) => {
  const { filters, fields, samples = [], loading } = props;

  return (
    <div style={{ height: 'inherit' }}>
      <Filter />
      <div className="data-query-body">
        {samples.length === 0 && !loading ? <NoDataNotification showHint={true} /> :
          <Table hover style={tableStyle}>
            <thead className="text-primary">
              <tr>
                <th>Time</th>
                {
                  filters.fields.length > 0 ? filters.fields.map((field, key) =>
                    <th key={field.id}>{field.name}</th>
                  ) :
                    fields.map((field) => {
                      return (
                        <th key={field.id}>{field.name}</th>
                      );
                    })
                }
              </tr>
            </thead>
            <tbody>
              {samples && samples.map((sample, key) => {
                return (
                  <tr key={key}>
                    <td>{moment(sample.time).format('DD.MM.YYYY HH:mm:ss')}</td>
                    {
                      filters.fields.length > 0 ? filters.fields.map(field =>
                        <td key={field.id}>{sample[field.name] && (sample[field.name].avg || <i>NaN</i>)}</td>
                      ) : fields.map(field =>
                        <td key={field.id}>{sample[field.name] && (sample[field.name].avg || <i>NaN</i>)}</td>
                      )
                    }
                  </tr>
                );
              })}
            </tbody>
          </Table>
        }
      </div>
    </div>
  );
}

const tableStyle = {
  whiteSpace: 'nowrap'
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    samples: state.samples.all,
    filters: state.filters,
    fields: state.fields.all,
    loading: state.loader.loading,
  }
}

export default connect(
  mapStateToProps,
  null
)(Tables);