import { combineReducers } from 'redux'
import account from './account'
import database from './database'
import dataloggers from './dataloggers'
import users from './users'
import loader from './loader'
import logs from './logs'
import schedules from './schedules'
import samples from './samples'
import tables from './tables'
import filters from './filters'
import stations from './stations'
import fields from './fields'
import licences from './licences'
import collector from './collector'
import views from './views'

export default combineReducers({
  account,
  database,
  dataloggers,
  users,
  loader,
  logs,
  schedules,
  samples,
  tables,
  filters,
  stations,
  fields,
  licences,
  collector,
  views,
})
