import { getValidIntervalArray } from 'helpers/intervals'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'

import {
  relogin,
  fetchStationTableFields,
  fetchSamples,
  clearSamples,
  fetchLastRecord,
  fetchSamplesInfo,
  filter,
  wsConnect,
} from '../../redux/actions'

const loaderStyle = {
  position: 'fixed',
  zIndex: 2000,
  top: 50 + '%',
  left: 50 + '%',
  width: 100 + 'px',
  transform: 'translate(' + -50 + 'px)',
  textAlign: 'center',
}

const DataLoader = props => {
  const {
    loading,
    filters,
    samplesInfo,
    authenticated,
    relogin,
    fetchStationTableFields,
    fetchSamples,
    fetchLastRecord,
    fetchSamplesInfo,
    clearSamples,
    filter,
    wsConnect,
  } = props

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!authenticated && token) {
      relogin(token)
    }
    if (authenticated) {
      wsConnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated])

  useEffect(() => {
    if (!filters.table.table_name) {
      clearSamples()
      return
    }
    fetchStationTableFields(filters.station.id, filters.table.table_name)

    fetchLastRecord(filters)
    fetchSamplesInfo(filters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.table, filters.interval, filters.start_time, filters.end_time])

  useEffect(() => {
    if (samplesInfo.min_interval === undefined) return

    const validPeriods = getValidIntervalArray()
    if (validPeriods.length > 0) {
      if (!validPeriods.includes(filters.period))
        filter({ period: validPeriods[0] })
      else {
        fetchSamples(filters)
      }
    } else {
      clearSamples(['all'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samplesInfo, filters.period])

  return (
    <div className="loader" style={loaderStyle}>
      <PulseLoader size={15} color={'#41c1f3'} loading={loading} />
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.loader.loading,
    filters: state.filters,
    samplesInfo: state.samples.info,
    authenticated: state.account.isAuthenticated,
  }
}

const mapDispatchToProps = {
  relogin,
  fetchStationTableFields,
  fetchSamples,
  fetchLastRecord,
  fetchSamplesInfo,
  clearSamples,
  filter,
  wsConnect,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataLoader)
