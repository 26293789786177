import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createLicence, fetchUser } from '../../redux/actions'

import {
  Button,
  Modal,
  Label,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'
import { generateString } from 'helpers/stringGenerator'

const AddLicence = props => {
  const { label, className, user, licences, createLicence, fetchUser } = props

  const initialValues = {
    name: '',
    duration: '1M',
  }

  const [created, setCreated] = useState(false)
  const [modal, setModal] = useState(false)
  const [values, setValues] = useState(initialValues)

  const generateLicenceName = () => {
    var name =
      (user.first_name || generateString(1))[0] +
      (user.last_name || generateString(1))[0] +
      '-' +
      values.duration +
      '-'
    var max = 0
    for (const licence of user.licences) {
      if (licence.name.startsWith(name)) {
        const current = Number(licence.name.slice(-2)) || 0
        if (current > max) max = current
      }
    }
    name += ('0' + (max + 1)).slice(-2)
    return name
  }

  useEffect(() => {
    if (user.id) {
      setValues({
        ...values,
        user_id: user.id,
        name: generateLicenceName(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, values.duration, modal])

  useEffect(() => {
    if (created) fetchUser(user.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licences])

  const toggle = () => {
    setModal(!modal)
  }

  const cancel = event => {
    event.preventDefault()
    toggle()
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.id]: event.target.value,
    })
  }

  const handleAdd = event => {
    event.preventDefault()
    createLicence(values)
    setCreated(true)
    toggle()
  }

  return (
    <div>
      <Button color="primary" onClick={toggle}>
        {label}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="licenceName">Name</Label>
              <Input
                disabled={true}
                type="text"
                id="name"
                placeholder="Licence Name"
                value={values.name}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup check>
              Licence duration
              <Label check>
                <Input
                  type="radio"
                  id="duration"
                  value="1w"
                  checked={values.duration === '1w'}
                  onChange={handleChange}
                />{' '}
                1 Week
              </Label>
              <Label check>
                <Input
                  type="radio"
                  id="duration"
                  value="1M"
                  checked={values.duration === '1M'}
                  onChange={handleChange}
                />{' '}
                1 Month
              </Label>
              <Label check>
                <Input
                  type="radio"
                  id="duration"
                  value="1Y"
                  checked={values.duration === '1Y'}
                  onChange={handleChange}
                />{' '}
                1 Year
              </Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={cancel}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleAdd}>
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    user: state.users.user,
    licences: state.licences.all,
  }
}

const mapDispatchToProps = {
  createLicence,
  fetchUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddLicence))
