import socketIOClient from 'socket.io-client'
import * as actions from './actions'
import * as Actions from './actionTypes'

const socketMiddleware = () => {
  let socket = null
  let subscriptions = []

  const subscribe = topic => {
    const topicPath = 'subscribe/' + topic
    if (!subscriptions.includes(topicPath)) {
      subscriptions.push(topicPath)
      if (socket) socket.emit(topicPath)
    }
  }

  const unsubscribe = topic => {
    const subscribeTopicPath = 'subscribe/' + topic
    const unsubscribeTopicPath = 'unsubscribe/' + topic
    if (subscriptions.includes(subscribeTopicPath)) {
      subscriptions = subscriptions.filter(
        subscription => subscription !== subscribeTopicPath
      )
      if (socket) socket.emit(unsubscribeTopicPath)
    }
  }

  // the middleware part of this function
  return store => next => action => {
    switch (action.type) {
      case Actions.WS_CONNECT:
        if (socket !== null) {
          socket.close()
        }
        const token = localStorage.getItem('token')
        socket = socketIOClient(process.env.REACT_APP_API_URL, {
          reconnection: true,
          reconnectionDelay: 500,
          auth: { token },
        })
        socket.on('collector', data => {
          store.dispatch(actions.setCollectorStatus(data))
        })
        socket.on('database', data => {
          store.dispatch(actions.setDatabaseInfo(data))
        })
        socket.on('logs', data => {
          store.dispatch(actions.addLog(data))
        })
        socket.on('schedules', data => {
          store.dispatch(actions.setSchedules(data))
        })
        socket.on('connect', () => {
          store.dispatch({
            type: Actions.SET_LOADER_STATE,
            payload: { wsConnected: true },
          })
          if (socket)
            subscriptions.forEach(subscription => {
              socket.emit(subscription)
            })
        })
        socket.on('disconnect', () => {
          store.dispatch({
            type: Actions.SET_LOADER_STATE,
            payload: { wsConnected: false },
          })
        })
        break

      case Actions.SUBSCRIBE_DATABASE:
        subscribe('database')
        break
      case Actions.UNSUBSCRIBE_DATABASE:
        unsubscribe('database')
        break
      case Actions.SUBSCRIBE_COLLECTOR:
        subscribe('collector')
        break
      case Actions.UNSUBSCRIBE_COLLECTOR:
        unsubscribe('collector')
        break
      case Actions.SUBSCRIBE_LOGS:
        subscribe('logs')
        break
      case Actions.UNSUBSCRIBE_LOGS:
        unsubscribe('logs')
        break
      case Actions.SUBSCRIBE_SCHEDULES:
        subscribe('schedules')
        break
      case Actions.UNSUBSCRIBE_SCHEDULES:
        unsubscribe('schedules')
        break

      case Actions.LOGOUT:
        if (socket !== null) {
          socket.close()
        }
        socket = null
        break
      default:
        break
    }
    return next(action)
  }
}

export default socketMiddleware()
