import React from 'react'
import { connect } from 'react-redux'

import { Route, Redirect, Switch } from 'react-router-dom'

import AdminLayout from 'layouts/Admin.jsx'
import Login from 'pages/Login'
import InvitationRegister from 'pages/InvitationRegister'

const App = props => {
  const { isAuthenticated } = props

  if (isAuthenticated) {
    return (
      <Switch>
        <Route path="/invitation/:token">
          <InvitationRegister />
        </Route>
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect to="/admin/data-query" />
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route path="/invitation/:token">
          <InvitationRegister />
        </Route>
        <Route path="*">
          <Login />
        </Route>
        <Redirect to="/login" />
      </Switch>
    )
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    isAuthenticated: state.account.isAuthenticated,
  }
}

export default connect(mapStateToProps, null)(App)
