import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { allow } from 'helpers/roleHandler'
import { Nav } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.activeRoute.bind(this)
    this.sidebar = React.createRef()
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}>
        <div className="logo" style={{ padding: '14px 20px 0px' }}>
          <div className="logo-img">
            <img src="/logo/full-dark.svg" alt="deltacloud-logo" />
          </div>
        </div>
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.show === false) return ''
              if (prop.allow && !allow(prop.allow, this.props.current_role))
                return ''
              return (
                <li
                  className={
                    this.activeRoute(prop.path) +
                    (prop.pro ? ' active-pro' : '')
                  }
                  key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active">
                    <FontAwesomeIcon
                      className="fa-fw"
                      size="2x"
                      style={{ marginRight: 14 + 'px', float: 'left' }}
                      icon={prop.icon}
                    />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              )
            })}
          </Nav>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    current_role: state.account.currentUser.role,
  }
}

export default connect(mapStateToProps, null)(Sidebar)
