import * as Actions from '../actionTypes'
import axios from 'axios'
import { notification } from 'components/CustomUI/Notifications'

const initialState = {
  currentUser: {},
  invitedUser: {},
  isAuthenticated: false,
  error: {},
}

const welcomeNotification = user => {
  notification({
    type: 'success',
    title: 'Login',
    message: `Welcome back ${user.first_name}`,
  })
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.LOGIN.FULFILLED(): {
      const user = action.payload.data
      axios.defaults.headers = { Authorization: 'bearer ' + user.token }
      localStorage.setItem('token', user.token)
      welcomeNotification(user)
      return {
        ...state,
        currentUser: user,
        isAuthenticated: true,
      }
    }
    case Actions.RELOGIN.FULFILLED(): {
      const user = action.payload.data
      const token = localStorage.getItem('token')
      axios.defaults.headers = { Authorization: 'bearer ' + token }
      welcomeNotification(user)
      return {
        ...state,
        currentUser: user,
        isAuthenticated: true,
      }
    }
    case Actions.LOGIN.REJECTED():
    case Actions.RELOGIN.REJECTED(): {
      localStorage.removeItem('token')
      return {
        ...state,
        currentUser: {},
      }
    }
    case Actions.LOGOUT: {
      localStorage.removeItem('token')
      notification({
        type: 'success',
        title: 'Logout',
        message: `You have been successfully logged out`,
      })
      return {
        ...state,
        currentUser: {},
        isAuthenticated: false,
      }
    }
    case Actions.UPDATE_PROFILE.FULFILLED(): {
      return {
        ...state,
        currentUser: action.payload.data,
      }
    }
    case Actions.FETCH_INVITED_USER.FULFILLED():
    case Actions.REGISTER_INVITED_USER.FULFILLED(): {
      return {
        ...state,
        invitedUser: action.payload.data,
      }
    }
    case Actions.FETCH_INVITED_USER.REJECTED(): {
      return {
        ...state,
        invitedUser: {
          error:
            action.payload?.response?.data?.message || action.payload.message,
        },
      }
    }
    default:
      return state
  }
}
