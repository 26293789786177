import * as Actions from "../actionTypes";

const initialState = {
    testConnectionResponse: {},
    tables: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Actions.TEST_DATALOGGER_CONNECTION.FULFILLED(): {
            return {
                ...state,
                testConnectionResponse: action.payload.data,
            };
        }
        case Actions.TEST_DATALOGGER_CONNECTION.REJECTED(): {
            return {
                ...state,
                testConnectionResponse: { description: "Connection test failed" },
            };
        }
        case Actions.FETCH_DATALOGGER_TABLES.FULFILLED(): {
            return {
                ...state,
                tables: action.payload.data,
            }
        }
        case Actions.CLEAR_TEST_DATA: {
            return initialState;
        }
        default:
            return state;
    }
}
