import React, { useState } from 'react'
import { connect } from 'react-redux'
import { updateView } from 'redux/actions'

import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

const OverwriteView = props => {
  const { children, className, disabled, selectedView, updateView } = props

  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  const showModal = () => {
    if (!disabled) setModal(true)
  }

  const handleCancel = event => {
    event.preventDefault()
    setModal(false)
  }

  const handleOverwrite = event => {
    event.preventDefault()
    if (selectedView.id) updateView(selectedView)
    setModal(false)
  }

  return (
    <div onClick={showModal}>
      {children}
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <div>
          <ModalBody>
            <h5>Overwrite View</h5>
            <p>
              Are you sure you want to overwrite view <b>{selectedView.name}</b>{' '}
              with currently set filters?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleOverwrite}>
              Overwrite
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    selectedView: state.views.view,
  }
}

const mapDispatchToProps = {
  updateView,
}

export default connect(mapStateToProps, mapDispatchToProps)(OverwriteView)
