import React, { useState } from 'react'
import classnames from 'classnames'

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

import OptionsBar from './OptionsBar/OptionsBar'
import TableQuery from './Tabs/Tables'
import GraphQuery from './Tabs/Graphs'
import Records from './Tabs/Records'

const DataQuery = props => {
  const [activeTab, setActiveTab] = useState('1')

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <div className="content">
      <Row>
        <Col xl="3">
          <OptionsBar />
        </Col>
        <Col xl="9">
          <Card style={cardStyle}>
            <CardBody style={cardBodyStyle}>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      toggle('1')
                    }}>
                    Last Record
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      toggle('2')
                    }}>
                    Table
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      toggle('3')
                    }}>
                    Graph
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} style={{ height: 95 + '%' }}>
                <TabPane tabId="1" style={{ height: 'inherit' }}>
                  <Records />
                </TabPane>
                <TabPane tabId="2" style={{ height: 'inherit' }}>
                  <TableQuery />
                </TabPane>
                <TabPane tabId="3">
                  <GraphQuery />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const cardStyle = {
  overflow: 'hidden',
  minHeight: 80 + 'vh',
}

const cardBodyStyle = {
  height: 100 + '%',
}

export default DataQuery
