import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from "moment";

import { fetchLog } from '../redux/actions';

import { Link } from 'react-router-dom';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col
} from "reactstrap";

const Log = (props) => {
  const { match, log, fetchLog } = props;

  useEffect(() => {
    if (match.params.id !== undefined) {
      fetchLog(match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isObject = value => {
    if (typeof value === 'object' && value !== null)
      return true;
    return false;
  }

  return (
    <div className="content">
      <Row>
        <Col md="6">
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>Log Information</h6>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover>
                <tbody>
                  <tr>
                    <th scope="row">Trigger</th>
                    <td>{log.trigger}</td>
                  </tr>
                  <tr>
                    <th scope="row">Description</th>
                    <td>{log.description}</td>
                  </tr>
                  <tr>
                    <th scope="row">User</th>
                    <td><Link to={'/admin/user/' + log.user_id}>{log.user_name}</Link></td>
                  </tr>
                  <tr>
                    <th scope="row">Created On</th>
                    <td>{moment(log.created_on).format("DD.MM.YYYY HH:mm:ss")}</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>Error Information</h6>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover>
                <tbody>
                  {log.trigger !== "DBEvent" || !log.error ? <tr></tr> : Object.keys(log.error).map((errorKey, key) => {
                    return (
                      <tr key={key}>
                        <th scope="row">{errorKey.charAt(0).toUpperCase() + errorKey.slice(1)}</th>
                        <td>{isObject(log.error[errorKey]) ? JSON.stringify(log.error[errorKey]) : log.error[errorKey]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>HTTP Method details</h6>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover>
                <tbody>
                  {!log.request ? <tr></tr> : Object.keys(log.request).map((requestKey, key) => {
                    return (
                      <tr key={key}>
                        <th scope="row">{requestKey.charAt(0).toUpperCase() + requestKey.slice(1)}</th>
                        <td>{isObject(log.request[requestKey]) ? JSON.stringify(log.request[requestKey]) : log.request[requestKey]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const cardStyle = {
  overflow: 'auto',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    log: state.logs.log
  }
}

const mapDispatchToProps = {
  fetchLog
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Log));
