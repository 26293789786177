import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { deleteUser } from '../../redux/actions'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'

const DeleteStation = props => {
  const { history, label, className, user, deleteUser } = props
  const { first_name, last_name, email, validated_email } = user

  const target = validated_email
    ? `${first_name} ${last_name}`
    : email?.split('@')[0]

  const [modal, setModal] = useState(false)
  const [typedName, setTypedName] = useState('')

  const toggle = () => {
    setModal(!modal)
  }

  const cancel = event => {
    event.preventDefault()
    toggle()
  }

  const handleChange = event => {
    setTypedName(event.target.value)
  }

  const handleDelete = event => {
    deleteUser(user.id)
  }

  useEffect(() => {
    if (user.deleted) {
      history.replace('/admin/users')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.deleted])

  return (
    <div>
      <Button color="danger" onClick={toggle}>
        {label}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <div>
          <ModalBody>
            <h5>Are you sure?</h5>
            <p className="text-danger">
              Unexpected bad things will happen if you don’t read this!{' '}
            </p>
            <p>
              This action cannot be undone. This will permanently delete the{' '}
              <b>{target}</b> user, stations, tables and data collected
              associated with the station. This will alse delete the licences
              associated with this user.
            </p>
            <p>
              Please type <b>{target}</b> to confirm
            </p>
            <Form>
              <FormGroup>
                <Input
                  type="text"
                  id="name"
                  value={typedName}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={cancel}>
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={handleDelete}
              disabled={typedName !== target}>
              Delete
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    user: state.users.user,
  }
}

const mapDispatchToProps = {
  deleteUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeleteStation))
