import * as Actions from '../actionTypes'

const initialState = {
  all: [],
  current: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_SCHEDULES.FULFILLED(): {
      return {
        ...state,
        all: action.payload.data,
        current: {},
      }
    }
    case Actions.SET_SCHEDULES: {
      return {
        ...state,
        all: action.payload,
        current: {},
      }
    }
    default:
      return state
  }
}
