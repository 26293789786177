import * as Actions from "../actionTypes";

const initialState = {
    info: {},
    activities: [],
    tables: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Actions.FETCH_DATABASE_INFO.FULFILLED(): {
            return {
                ...state,
                info: action.payload.data,
            };
        }
        case Actions.SET_DATABASE_INFO: {
            return {
                ...state,
                info: action.payload,
            };
        }
        default:
            return state;
    }
}
