import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  fetchCollectorStatus,
  subscribeCollector,
  unsubscribeCollector,
} from 'redux/actions'
import { secondsToObject } from 'helpers/timeHelper'

import moment from 'moment'

// reactstrap components
import { Card, CardBody, CardHeader, Table, Row, Col } from 'reactstrap'

import CustomCheckbox from 'components/CustomUI/Checkbox'
import CollectorActionBar from './CollectorActionBar'

const Collector = props => {
  const {
    status,
    fetchCollectorStatus,
    subscribeCollector,
    unsubscribeCollector,
  } = props

  useEffect(() => {
    fetchCollectorStatus()
    subscribeCollector()
    return () => unsubscribeCollector()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <CollectorActionBar />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card style={cardStyle}>
            <CardBody>
              <Table hover style={tableStyle}>
                <thead className="text-primary">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Collection Enabled</th>
                    <th>Collect Base Date</th>
                    <th>Collection Interval</th>
                    <th>Retry Interval</th>
                    <th>No of retries</th>
                    <th>Last Collect OK</th>
                    <th>Last Collect Date</th>
                    <th>Last Collect Time</th>
                    <th>Next Collect Date</th>
                    <th>Retries Left</th>
                    <th>Collect Now</th>
                    <th>Collect Active</th>
                    <th>More Data</th>
                  </tr>
                </thead>
                <tbody>
                  {status.stations &&
                    status.stations.map((station, key) => {
                      return (
                        <tr key={key}>
                          <td>{station.id}</td>
                          <td>{station.alias}</td>
                          <td>
                            <CustomCheckbox
                              value={station.collection_enabled}
                            />
                          </td>
                          <td>
                            {moment(station.collect_base_date).format(
                              'DD.MM.YYYY HH:mm:ss'
                            )}
                          </td>
                          <td>
                            {secondsToObject(station.collection_interval).print}
                          </td>
                          <td>
                            {
                              secondsToObject(station.collection_retry_interval)
                                .print
                            }
                          </td>
                          <td>{station.number_of_retries}</td>
                          <td>
                            <CustomCheckbox value={station.last_collect_ok} />
                          </td>
                          <td>
                            {moment(station.last_collect_date).format(
                              'DD.MM.YYYY HH:mm:ss'
                            )}
                          </td>
                          <td>{station.last_collect_time}</td>
                          <td>
                            {moment(station.next_collect_date).format(
                              'DD.MM.YYYY HH:mm:ss'
                            )}
                          </td>
                          <td>{station.retries_left}</td>
                          <td>
                            <CustomCheckbox value={station.collect_now} />
                          </td>
                          <td>
                            <CustomCheckbox value={station.collect_active} />
                          </td>
                          <td>
                            <CustomCheckbox
                              value={station.more_data_available}
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>Collector Status</h6>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover>
                <tbody>
                  <tr>
                    <th scope="row">Enabled</th>
                    <td>
                      <CustomCheckbox value={status.enabled} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Currently Collecting</th>
                    <td>
                      <CustomCheckbox value={status.running} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Active Collections</th>
                    <td>
                      {status.active_collections}{' '}
                      {status.stations && '/ ' + status.stations.length}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const cardStyle = {
  overflow: 'auto',
}

const tableStyle = {
  whiteSpace: 'nowrap',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    status: state.collector.status,
  }
}

const mapDispatchToProps = {
  fetchCollectorStatus,
  subscribeCollector,
  unsubscribeCollector,
}

export default connect(mapStateToProps, mapDispatchToProps)(Collector)
