import { notification } from 'components/CustomUI/Notifications'
import * as Actions from '../actionTypes'

const initialState = {
  loading: false,
  loadingCount: 0,
  wsConnected: false,
  error: '',
}

export default function(state = initialState, action) {
  if (action.type.includes('PENDING')) {
    const loadingCount = state.loadingCount + 1
    state = {
      ...state,
      loadingCount: loadingCount,
      loading: true,
      error: '',
    }
  } else if (action.type.includes('FULFILLED')) {
    const loadingCount = state.loadingCount - 1
    state = {
      ...state,
      loadingCount,
      error: '',
    }
    if (loadingCount === 0) {
      state = {
        ...state,
        loading: false,
      }
    }
  } else if (action.type.includes('REJECTED')) {
    const loadingCount = state.loadingCount - 1
    const error = action.payload
    let message = ''
    try {
      const msg = error.response.data.message
      if (msg) message = msg
      else message = error.message
    } catch (err) {
      message = error.message
    }
    state = {
      ...state,
      loadingCount,
      error: message,
    }
    if (loadingCount === 0) {
      state = {
        ...state,
        loading: false,
      }
    }
    notification({
      type: 'error',
      title: 'Error',
      message,
    })
  } else if (action.type === Actions.SET_LOADER_STATE) {
    state = {
      ...state,
      ...action.payload,
    }
  }
  return state
}
