import * as Actions from "../actionTypes";

const initialState = {
    all: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Actions.FETCH_LICENCES.FULFILLED(): 
        case Actions.CREATE_LICENCE.FULFILLED(): {
            return {
                ...state,
                all: action.payload.data,
            };
        }
        default:
            return state;
    }
}
