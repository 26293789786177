import React, { useState } from 'react'
import { connect } from 'react-redux'
import { fetchSamplesCSV } from '../../../redux/actions'

import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

const ExportCSV = props => {
  const { children, className, disabled, fetchSamplesCSV } = props

  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  const showModal = () => {
    if (!disabled) setModal(true)
  }

  const handleCancel = event => {
    event.preventDefault()
    setModal(false)
  }

  const handleExport = event => {
    event.preventDefault()
    fetchSamplesCSV()
    setModal(false)
  }

  return (
    <div onClick={showModal}>
      {children}
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <div>
          <ModalBody>
            <h5>Export CSV</h5>
            <p>
              This action will generate and download CSV file for selected
              source and filters.
              <br />
              Note that data will be exported in <b>RAW format</b> as they are
              saved on the station, so the output may be different then the data
              you see in the app.
            </p>
            <p>
              Press <b>Export</b> to start downloading.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleExport}>
              Export
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    //user: state.users.user,
  }
}

const mapDispatchToProps = {
  fetchSamplesCSV,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportCSV)
