import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchUsers } from '../../redux/actions'

import moment from 'moment'

// reactstrap components
import { Card, CardBody, Table, Row, Col, Badge } from 'reactstrap'
import UsersActionBar from './UsersActionBar'

const Users = props => {
  const { users, fetchUsers } = props

  useEffect(() => {
    fetchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <UsersActionBar />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card style={cardStyle}>
            <CardBody>
              <Table hover style={tableStyle}>
                <thead className="text-primary">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Licences</th>
                    <th>Role</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <Link to={'/admin/user/' + user.id}>
                            {user.verified_email ? (
                              `${user.first_name} ${user.last_name}`
                            ) : (
                              <i>Pending registration...</i>
                            )}
                          </Link>
                        </td>
                        <td>{user.email}</td>
                        <td>{user.no_of_licences}</td>
                        <td>
                          <Badge
                            className={`role-badge-${user.role?.toLowerCase()}`}>
                            {user.role}
                          </Badge>
                        </td>
                        <td>
                          {moment(user.created_on).format(
                            'DD.MM.YYYY HH:mm:ss'
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const cardStyle = {
  overflow: 'auto',
  height: 73 + 'vh',
}

const tableStyle = {
  whiteSpace: 'nowrap',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    users: state.users.all,
  }
}

const mapDispatchToProps = {
  fetchUsers,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
