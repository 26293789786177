const store = require('../redux/store').default;

// Constants for Roles
const Master = "Master";
const Admin = "Admin";
const User = "User";

const allow = (role, current_role) => {
    return current_role && (current_role === Master || current_role === role)
}

const getRole = () => {
    return store.getState().account.currentUser.role;
}

const isMaster = () => {
    return getRole() === Master;
}

const isAdmin = () =>{
    const role = getRole();
    return role === Master || role === Admin;
}

const isUser = () =>{
    return getRole() === User;
}

module.exports = {
    allow,
    isMaster, isAdmin, isUser
};