import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import moment from "moment";

import { Table } from "reactstrap";

import NoDataNotification from "components/NoDataNotification/NoDataNotification";

const Tables = (props) => {
  const { lastRecord, fields, loading } = props;

  const [field, setField] = useState(0);

  useEffect(() => {
    if (lastRecord && lastRecord.time) {
      var field = {};
      for (const fieldFromList of fields) {
        field[fieldFromList.name] = { units: fieldFromList.units, type: fieldFromList.type };
      }
      setField(field);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRecord]);

  return (
    <div className="data-query-body">
        {!lastRecord || !lastRecord.time || !field || loading ? <NoDataNotification /> :
          <Table hover style={tableStyle}>
            <thead className="text-primary">
              <tr>
                <th>Field</th>
                <th>Value</th>
                <th>Type</th>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Time</th>
                <td>{moment(lastRecord.time).format('DD.MM.YYYY HH:mm:ss')}</td>
                <td>{"/"}</td>
                <td>{"/"}</td>
              </tr>
              {Object.keys(lastRecord).filter(key => key !== "time").map((key, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{key}</th>
                    <td>{lastRecord[key] || 'NaN'}</td>
                    <td>{field[key] && field[key].type}</td>
                    <td>{field[key] && field[key].units}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        }
    </div>
  );
}

const tableStyle = {
  whiteSpace: 'nowrap'
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    lastRecord: state.samples.lastRecord,
    firstTimestamp: state.samples.info.first_timestamp,
    fields: state.fields.all,
    loading: state.loader.loading,
  }
}

export default connect(
  mapStateToProps,
  null
)(Tables);