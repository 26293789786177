import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckSquare as iconTrue,
    faMinusSquare as iconFalse
} from '@fortawesome/free-solid-svg-icons';

const InteractiveCheckbox = (props) => {
    const { id, name, checked, color, trueColor, falseColor, size, onChange, children } = props;

    const true_color = color || trueColor || "#70bd70";
    const false_color = color || falseColor || "#ff7800";

    const handleClick = () => {
        onChange({
            target: {
                id,
                name,
                checked: !checked,
                type: "checkbox"
            }
        })
    }

    return (
        <div onClick={handleClick} style={divStyle}>
            <FontAwesomeIcon
                icon={checked ? iconTrue : iconFalse}
                size={size || "lg"}
                color={checked ? true_color : false_color}
            />
            {children}
        </div>
    )
}

const divStyle = {
    cursor: 'pointer'
}

export default InteractiveCheckbox;
