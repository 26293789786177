import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle as iconTrue,
  faTimesCircle as iconFalse,
} from '@fortawesome/free-solid-svg-icons'

const Checkbox = props => {
  const { value } = props
  if (value) {
    return <FontAwesomeIcon icon={iconTrue} size="lg" color={'#70bd70'} />
  }
  return <FontAwesomeIcon icon={iconFalse} size="lg" color={'#ff7800'} />
}

export default Checkbox
