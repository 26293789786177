import Dashboard from 'views/Dashboard'
import DataQuery from 'views/DataQuery/DataQuery'
import Users from 'views/Users/Users'
import User from 'views/User/User'
import Stations from 'views/Stations/Stations'
import Station from 'views/Station/Station'
import Administration from 'views/Administration'
import Log from 'views/Log'
import Licences from 'views/Licences'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  fas,
  faTachometerAlt,
  faTable,
  faChartLine,
  faSitemap,
  faUsers,
  faUserShield,
  faClipboardList,
  faShieldAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  fas,
  faTachometerAlt,
  faTable,
  faChartLine,
  faSitemap,
  faUsers,
  faUserShield,
  faClipboardList,
  faShieldAlt,
  faUser
)

var routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'tachometer-alt',
    component: Dashboard,
    layout: '/admin',
    show: false,
  },
  {
    path: '/stations',
    name: 'Stations',
    icon: 'sitemap',
    component: Stations,
    layout: '/admin',
    allow: 'Admin',
  },
  {
    path: '/data-query',
    name: 'Data Query',
    icon: 'table',
    component: DataQuery,
    layout: '/admin',
  },
  {
    path: '/station/:id',
    name: 'Station',
    component: Station,
    layout: '/admin',
    show: false,
  },
  {
    path: '/licences',
    name: 'Licences',
    icon: 'shield-alt',
    component: Licences,
    layout: '/admin',
    allow: 'Admin',
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'users',
    component: Users,
    layout: '/admin',
    allow: 'Admin',
  },
  {
    path: '/user/:id',
    name: 'User Profile',
    component: User,
    layout: '/admin',
    show: false,
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: 'user',
    component: User,
    layout: '/admin',
  },
  {
    path: '/log/:id',
    name: 'Log',
    component: Log,
    layout: '/admin',
    show: false,
  },
  {
    path: '/database',
    name: 'Administration',
    icon: 'user-shield',
    component: Administration,
    layout: '/admin',
    allow: 'Master',
  },
]

export default routes
