import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { Button, Card, CardHeader, CardBody, Table, Row, Col } from 'reactstrap'
import DeleteStationModal from './DeleteStationModal'
import TestConnectionModal from './TestConnectionModal'
import CustomCheckbox from 'components/CustomUI/Checkbox'
import AddOrEditStation from 'components/AddOrEditStation'
import { secondsToObject } from 'helpers/timeHelper'
import { isMaster } from 'helpers/roleHandler'
import EditLicence from 'components/EditLicence/EditLicence'
import { fetchStation, autoUpdateStation } from 'redux/actions'

const Station = props => {
  const { match, station, fetchStation, loading, autoUpdateStation } = props

  const fetchData = () => {
    if (match.params.id !== undefined) {
      fetchStation(match.params.id)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAutoUpdate = () => {
    autoUpdateStation(station.id)
  }

  return (
    <div className="content">
      <div className="action-bar">
        <AddOrEditStation station={station} style={optionStyle} label="Edit" />
        <DeleteStationModal style={optionStyle} buttonLabel="Delete" />
      </div>
      <Row>
        <Col md="6">
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>Station Details</h6>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover>
                <tbody>
                  <tr>
                    <th scope="row">Name</th>
                    <td>{station.alias}</td>
                  </tr>
                  <tr>
                    <th scope="row">Owner</th>
                    <td>
                      <Link to={'/admin/user/' + station.owner_id}>
                        {station.user_name?.trim() || (
                          <i>pending registration...</i>
                        )}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Station Name</th>
                    <td>{station.station_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Model</th>
                    <td>{station.model}</td>
                  </tr>
                  <tr>
                    <th scope="row">Serial Number</th>
                    <td>{station.serial_no}</td>
                  </tr>
                  <tr>
                    <th scope="row">OS Version</th>
                    <td>{station.os_version}</td>
                  </tr>
                  <tr>
                    <th scope="row">Program Name</th>
                    <td>{station.prog_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">IP Address</th>
                    <td>{station.ip_address}</td>
                  </tr>
                  <tr>
                    <th scope="row">Security Code</th>
                    <td>{station.security_code || <i>none</i>}</td>
                  </tr>
                  <tr>
                    <th scope="row">Last Edited On</th>
                    <td>
                      {moment(station.edited_on).format('DD.MM.YYYY HH:mm:ss')}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Created On</th>
                    <td>
                      {moment(station.created_on).format('DD.MM.YYYY HH:mm:ss')}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div style={{ textAlign: 'center' }}>
                <Button
                  color="default"
                  disabled={loading}
                  onClick={handleAutoUpdate}
                  style={{ marginTop: 0 }}>
                  Update From Station
                </Button>
                <TestConnectionModal
                  style={{ marginTop: 0 }}
                  buttonLabel="Test Connection"
                />
              </div>
            </CardBody>
          </Card>
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>Data Collection</h6>
              </div>
            </CardHeader>
            <CardBody>
              <Table hover>
                <tbody>
                  <tr>
                    <th scope="row">Collection Enabled</th>
                    <td>
                      <CustomCheckbox value={station.collection_enabled} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Extra Response Time</th>
                    <td>{station.extra_response_time} s</td>
                  </tr>
                  <tr>
                    <th scope="row">Collect Base Date</th>
                    <td>
                      {moment(station.collect_base_date).format(
                        'DD.MM.YYYY HH:mm:ss'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Collection Interval</th>
                    <td>
                      {secondsToObject(station.collection_interval).print}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Collection Retry Interval</th>
                    <td>
                      {secondsToObject(station.collection_retry_interval).print}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Number Of Reties</th>
                    <td>{station.number_of_retries}</td>
                  </tr>
                  <tr>
                    <th scope="row">Last Collection Date</th>
                    <td>
                      {station.last_collect_date ? (
                        moment(station.last_collect_date).format(
                          'DD.MM.YYYY HH:mm:ss'
                        )
                      ) : (
                        <i>undefined</i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Next Collection Date</th>
                    <td>
                      {station.next_collect_date ? (
                        moment(station.next_collect_date).format(
                          'DD.MM.YYYY HH:mm:ss'
                        )
                      ) : (
                        <i>undefined</i>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>Licence</h6>
              </div>
            </CardHeader>
            <CardBody>
              {!station.licence ? (
                ''
              ) : !station.licence.name ? (
                <div style={{ textAlign: 'center', marginBottom: 10 + 'px' }}>
                  <i>There is no licence assigned to this station</i>
                </div>
              ) : (
                <Table hover>
                  <tbody>
                    <tr>
                      <th scope="row">Name</th>
                      <td>
                        {isMaster() ? (
                          <EditLicence
                            label={station.licence.name}
                            licence={station.licence}
                            onUpdate={fetchData}
                          />
                        ) : (
                          station.licence.name
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Valid</th>
                      <td>
                        <CustomCheckbox
                          value={
                            new Date(station.licence.expiry_date) >= new Date()
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Expiry Date</th>
                      <td>
                        {moment(station.licence.expiry_date).format(
                          'DD.MM.YYYY'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Created On</th>
                      <td>
                        {moment(station.licence.created_on).format(
                          'DD.MM.YYYY'
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
          <Card style={cardStyle}>
            <CardHeader>
              <div style={{ textAlign: 'center' }}>
                <h6>Station Tables</h6>
              </div>
            </CardHeader>
            <CardBody>
              {!station.tables || station.tables.length === 0 ? (
                <div style={{ textAlign: 'center', marginBottom: 10 + 'px' }}>
                  <i>No tables found for this station</i>
                </div>
              ) : (
                <Table hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th style={{ textAlign: 'center' }}>
                        Collection Enabled
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!station.tables ? (
                      <tr></tr>
                    ) : (
                      station.tables.map(
                        (table, index) =>
                          !table.deleted && (
                            <tr key={index}>
                              <td>{table.name}</td>
                              <td style={{ textAlign: 'center' }}>
                                <CustomCheckbox
                                  value={table.collection_enabled}
                                />
                              </td>
                            </tr>
                          )
                      )
                    )}
                  </tbody>
                </Table>
              )}
              <div style={{ textAlign: 'center' }}>
                <AddOrEditStation
                  station={station}
                  defaultTab="1"
                  label="Manage Tables"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const optionStyle = {
  marginRight: 5 + 'px',
}

const cardStyle = {
  overflow: 'auto',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    station: state.stations.station,
    loading: state.loader.loading,
  }
}

const mapDispatchToProps = {
  fetchStation,
  autoUpdateStation,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Station))
