import React from 'react'

const Dot = props => {
  const { size, color } = props
  const style = {
    width: size,
    height: size,
    backgroundColor: color,
  }

  return <span className="dot" style={style} />
}

export default Dot
