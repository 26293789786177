import * as Actions from "../actionTypes";
import moment from 'moment';
import fileSaver from 'file-saver';

const initialState = {
    info: {},
    lastRecord: {},
    all: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Actions.FETCH_SAMPLES.FULFILLED(): {
            return {
                ...state,
                all: action.payload.data,
            };
        }
        case Actions.FETCH_SAMPLES_CSV.FULFILLED(): {
            fileSaver.saveAs(new Blob([action.payload.data], {type: "text/csv;charset=utf-8"}), "DTC_export_" + moment().format('DD-MM-YYYY_HH-mm-ss') + ".csv");
            return state;
        }
        case Actions.FETCH_LASTRECORD.FULFILLED(): {
            return {
                ...state,
                lastRecord: action.payload.data,
            };
        }
        case Actions.FETCH_SAMPLES_INFO.FULFILLED(): {
            return {
                ...state,
                info: action.payload.data,
            };
        }
        case Actions.CLEAR_SAMPLES: {
            if(!action.payload || action.payload.length === 0)
            return {
                ...initialState
            }
            return {
                info: action.payload.includes("info") ? initialState.info : state.info,
                lastRecord: action.payload.includes("lastRecord") ? initialState.info : state.lastRecord,
                all: action.payload.includes("all") ? initialState.all : state.all,
            };
        }
        default:
            return state;
    }
}
