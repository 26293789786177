import React from 'react'
import { connect } from 'react-redux'
import { fetchDatabaseInfo } from 'redux/actions'

import { Button } from 'reactstrap'

const DatabaseActionBar = props => {
  const { fetchDatabaseInfo } = props

  const handleRefresh = () => {
    fetchDatabaseInfo()
  }

  return (
    <div style={{ display: 'inline-flex' }}>
      <Button color="primary" onClick={handleRefresh}>
        Refresh
      </Button>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {}
}

const mapDispatchToProps = {
  fetchDatabaseInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseActionBar)
