import * as Actions from "../actionTypes";

const initialState = {
    all: [],
    view: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Actions.FETCH_VIEWS.FULFILLED():
        case Actions.CREATE_VIEW.FULFILLED():
        case Actions.UPDATE_VIEW.FULFILLED(): {
            return {
                ...state,
                all: action.payload.data,
            };
        }
        case Actions.SET_VIEW: {
            return {
                ...state,
                view: action.payload,
            }
        }
        case Actions.DELETE_VIEW.FULFILLED(): {
            return {
                ...state,
                all: action.payload.data,
                view: {}
            }
        }
        default:
            return state;
    }
}
