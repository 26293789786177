import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchLicences } from '../redux/actions'

import moment from 'moment'

// reactstrap components
import { Card, CardBody, Table, Row, Col } from 'reactstrap'
import CustomCheckbox from 'components/CustomUI/Checkbox'

import { isMaster } from 'helpers/roleHandler'
import EditLicence from 'components/EditLicence/EditLicence'

const Licences = props => {
  const { licences, fetchLicences } = props

  const fetchData = () => {
    fetchLicences()
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card style={cardStyle}>
            <CardBody>
              <Table hover style={tableStyle}>
                <thead className="text-primary">
                  <tr>
                    <th>Name</th>
                    <th>User</th>
                    <th>Station</th>
                    <th>Valid</th>
                    <th>Expiry Date</th>
                  </tr>
                </thead>
                <tbody>
                  {licences.map((licence, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {isMaster() ? (
                            <EditLicence
                              label={licence.name}
                              licence={licence}
                              onUpdate={fetchData}
                            />
                          ) : (
                            licence.name
                          )}
                        </td>
                        <td>
                          <Link to={'/admin/user/' + licence.user_id}>
                            {licence.user_name}
                          </Link>
                        </td>
                        <td>
                          {licence.station_name ? (
                            <Link to={'/admin/station/' + licence.station_id}>
                              {licence.station_name}
                            </Link>
                          ) : (
                            <i>unassigned</i>
                          )}
                        </td>
                        <td>
                          <CustomCheckbox
                            value={new Date(licence.expiry_date) >= new Date()}
                          />
                        </td>
                        <td>
                          {moment(licence.expiry_date).format('DD.MM.YYYY')}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const cardStyle = {
  overflow: 'auto',
  height: 73 + 'vh',
}

const tableStyle = {
  whiteSpace: 'nowrap',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    licences: state.licences.all,
  }
}

const mapDispatchToProps = {
  fetchLicences,
}

export default connect(mapStateToProps, mapDispatchToProps)(Licences)
