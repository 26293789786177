const colors = {
  GREEN: '#20c997', //'#20c997' : '#d63384'
  RED: '#d63384',
  DARK_GRAY: '#6c757d',
}

const boolToColor = value => {
  if (value) return colors.GREEN
  else return colors.RED
}

export { colors, boolToColor }
