import * as Actions from "../actionTypes";

const initialState = {
    status: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Actions.FETCH_COLLECTOR_STATUS.FULFILLED(): {
            return {
                ...state,
                status: action.payload.data,
            };
        }
        case Actions.SET_COLLECTOR_STATUS: {
            return {
                ...state,
                status: action.payload,
            };
        }
        default:
            return state;
    }
}
