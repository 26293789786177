import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ResponsiveLine } from '@nivo/line'
import { Table } from 'reactstrap'
import NoDataNotification from 'components/NoDataNotification/NoDataNotification'
import Filter from '../../FilterBar/FilterBar'
import InteractiveCheckbox from 'components/InteractiveCheckbox/InteractiveCheckbox'
import { intervalToSeconds } from 'helpers/intervals'
import { generateGraphData } from './helpers'

import './styles.scss'

const commonProperties = {
  height: 400,
  margin: { top: 20, right: 20, bottom: 60, left: 80 },
  animate: true,
  enableSlices: 'x',
}

const Graphs = props => {
  const {
    selectedFields,
    selectedPeriod,
    rawMinInterval,
    samples = [],
    loading,
  } = props

  const [graphData, setGraphData] = useState([])
  const [samplesData, setSamplesData] = useState([])
  const [dateFormat, setDateFormat] = useState('%d.%m.%Y %H:%M:%S')

  const handleChange = event => {
    var changedIndex = -1
    for (const [index, sampleData] of samplesData.entries()) {
      if (sampleData.name === event.target.id) {
        changedIndex = index
        break
      }
    }
    // Update only if there is change
    if (changedIndex >= 0) {
      const changedSampleData = samplesData[changedIndex]

      var newSampleData = {}

      const checkboxName = event.target.name
      const state = event.target.checked
      const newSamples = changedSampleData.samples.map(sample => {
        return {
          ...sample,
          display: sample.type === checkboxName ? state : sample.display,
        }
      })
      newSampleData = {
        ...changedSampleData,
        samples: newSamples,
      }
    }
    setSamplesData([
      ...samplesData.slice(0, changedIndex),
      newSampleData,
      ...samplesData.slice(changedIndex + 1),
    ])
  }

  useEffect(() => {
    if (samples.length === 0) {
      setGraphData([])
      setSamplesData([])
      return
    }

    const {
      modifiedSamplesData,
      dateFormat: newDateFormat,
    } = generateGraphData(samples, samplesData, selectedFields)

    setDateFormat(newDateFormat)
    setSamplesData(modifiedSamplesData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samples, selectedFields])

  useEffect(() => {
    var data = []
    for (const sampleData of samplesData) {
      data = [
        ...data,
        ...sampleData.samples.filter(sample => sample.display === true),
      ]
    }
    setGraphData(data)
  }, [samplesData])

  return (
    <div>
      <Filter />
      <div className="dataQuery-body-options">
        <Table size="sm" borderless className="graph-options">
          <tbody>
            {samplesData.map(sampleData => (
              <tr key={sampleData.name}>
                <td>{sampleData.name}</td>
                {rawMinInterval !== intervalToSeconds(selectedPeriod)
                  ? sampleData.samples.map(sample => (
                      <td key={sample.type}>
                        <InteractiveCheckbox
                          id={sampleData.name}
                          name={sample.type}
                          checked={sample.display}
                          onChange={handleChange}
                          trueColor={sample.color}
                          falseColor={'#ececec'}>
                          {' '}
                          {sample.type}
                        </InteractiveCheckbox>
                      </td>
                    ))
                  : sampleData.samples
                      .filter(sample => sample.type === 'avg')
                      .map(sample => (
                        <td key={sample.type}>
                          <InteractiveCheckbox
                            id={sampleData.name}
                            name={sample.type}
                            checked={sample.display}
                            onChange={handleChange}
                            trueColor={sample.color}
                            falseColor={'#ececec'}>
                            {' '}
                            raw
                          </InteractiveCheckbox>
                        </td>
                      ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {graphData.length === 0 && !loading ? (
        <NoDataNotification showHint={true}>
          {samples.length > 0 &&
            'Hint: Data is loaded, select fields in the Source menu'}
        </NoDataNotification>
      ) : (
        <div className="line-chart">
          <ResponsiveLine
            {...commonProperties}
            data={graphData}
            colors={graphData.map(c => c.color)}
            xScale={{
              type: 'time',
              format: '%d.%m.%Y %H:%M:%S',
              precision: 'second',
            }}
            xFormat="time:%d.%m.%Y %H:%M:%S"
            yScale={{
              type: 'linear',
              stacked: false,
              min: 'auto',
              max: 'auto',
            }}
            axisBottom={{
              format: dateFormat,
              legendOffset: -12,
              tickRotation: -30,
            }}
            curve={'monotoneX'}
            enablePointLabel={false}
            useMesh={true}
            enableSlices={false}
            enablePoints={false}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    samples: state.samples.all,
    rawMinInterval: state.samples.info.min_interval,
    selectedFields: state.filters.fields,
    selectedPeriod: state.filters.period,
    loading: state.loader.loading,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Graphs)
