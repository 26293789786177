import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { filter } from 'redux/actions'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

const ExportCSV = props => {
  const { children, className, disabled, filter, sig_digits } = props

  const [modal, setModal] = useState(false)
  const [settings, setSettings] = useState({
    active: false,
    sigDigits: 4,
  })

  useEffect(() => {
    setSettings({
      active: sig_digits > 0,
      sigDigits: sig_digits > 0 ? sig_digits : settings.sigDigits,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sig_digits])

  const toggle = () => {
    setModal(!modal)
  }

  const showModal = () => {
    if (!disabled) setModal(true)
  }

  const handleCancel = event => {
    event.preventDefault()
    setModal(false)
  }

  const handleSave = event => {
    event.preventDefault()
    filter({
      sig_digits: settings.active ? settings.sigDigits : 0,
    })
    setModal(false)
  }

  const handleSwitchChange = event => {
    /*
    setStationValues({
      ...stationValues,
      [event.target.id]: event.target.checked,
		})
		*/
    setSettings({
      ...settings,
      active: event.target.checked,
    })
  }
  const handleChange = event => {
    /*
    setStationValues({
      ...stationValues,
      [event.target.id]: event.target.value,
		})
		*/
    setSettings({
      ...settings,
      sigDigits: event.target.value,
    })
  }

  return (
    <div onClick={showModal}>
      {children}
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <div>
          <ModalBody>
            <Form>
              <Label for="collectionEnabled">
                Display only significant digits
              </Label>
              <FormGroup>
                <Input
                  type="switch"
                  id="collection_enabled"
                  label={
                    settings.active
                      ? 'displaying formatted data'
                      : 'displaying raw data'
                  }
                  checked={settings.active}
                  onChange={handleSwitchChange}
                />
              </FormGroup>
              {settings.active && (
                <FormGroup>
                  <Label for="sig_digits">Significant digits</Label>
                  <Input
                    type="number"
                    id="sig_digits"
                    placeholder="e.g. 5"
                    value={settings.sigDigits}
                    onChange={handleChange}
                  />
                </FormGroup>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    sig_digits: state.filters.sig_digits,
  }
}

const mapDispatchToProps = {
  filter,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportCSV)
