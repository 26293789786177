import React from 'react'
import AddOrEditStation from 'components/AddOrEditStation'

const StationActionBar = () => {
  return (
    <div className="action-bar">
      <AddOrEditStation label="Add New" />
    </div>
  )
}

export default StationActionBar
