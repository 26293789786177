import React, { useState } from 'react'
import { connect } from 'react-redux'
import { logout } from 'redux/actions'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser as userIcon } from '@fortawesome/free-solid-svg-icons'

const UserDropdown = props => {
  const { currentUser, logout } = props
  const { first_name, role } = currentUser
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(prevValue => !prevValue)
  }
  return (
    <Dropdown className="user-dropdown" isOpen={isOpen} toggle={toggle}>
      <DropdownToggle
        className="button-container"
        data-toggle="dropdown"
        tag="div">
        <div className="button">
          <FontAwesomeIcon className="icon" icon={userIcon} size="lg" />
          <span className="username">{first_name}</span>
        </div>
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem header>
          <div>Hello {first_name}</div>
          <div className="role">{role}</div>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={logout}>Logout</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    currentUser: state.account.currentUser,
  }
}

const mapDispatchToProps = {
  logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown)
