import * as Actions from '../actionTypes'

const initialState = {
  all: [],
  log: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_LOGS.FULFILLED(): {
      return {
        ...state,
        all: action.payload.data,
        log: {},
      }
    }
    case Actions.FETCH_LOG.FULFILLED(): {
      return {
        ...state,
        log: action.payload.data,
      }
    }
    case Actions.ADD_LOG: {
      let newAll = [...state.all]
      if (state.count > 0) {
        newAll.pop()
      }
      return {
        ...state,
        all: [action.payload, ...newAll],
      }
    }
    default:
      return state
  }
}
