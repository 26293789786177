import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchStations } from '../../redux/actions'

import moment from 'moment'

import StationActionBar from './StationActionBar'

// reactstrap components
import { Card, CardBody, Table, Row, Col } from 'reactstrap'
import CustomCheckbox from 'components/CustomUI/Checkbox'

const Stations = props => {
  const { stations, fetchStations } = props

  useEffect(() => {
    fetchStations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <StationActionBar />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card style={cardStyle}>
            <CardBody>
              <Table hover style={tableStyle}>
                <thead className="text-primary">
                  <tr>
                    <th>Name</th>
                    <th>Model</th>
                    <th>Serial No</th>
                    <th>IP Address</th>
                    <th>Enabled</th>
                    <th>Licence Valid</th>
                    <th>Owner</th>
                    <th>Created On</th>
                  </tr>
                </thead>
                <tbody>
                  {stations.map((station, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          <Link to={'/admin/station/' + station.id}>
                            {station.alias}
                          </Link>
                        </td>
                        <td>{station.model}</td>
                        <td>{station.serial_no}</td>
                        <td>{station.ip_address}</td>
                        <td>
                          <CustomCheckbox value={station.collection_enabled} />
                        </td>
                        <td>
                          <CustomCheckbox
                            value={
                              new Date(station.licence_expiry_date) >=
                              new Date()
                            }
                          />
                        </td>
                        <td>
                          <Link to={'/admin/user/' + station.owner_id}>
                            {station.user_name?.trim() || <i>pending...</i>}
                          </Link>
                        </td>
                        <td>
                          {!station.created_on
                            ? 'Unknown'
                            : moment(station.created_on).format(
                                'DD.MM.YYYY HH:mm:ss'
                              )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const cardStyle = {
  overflow: 'auto',
  height: 73 + 'vh',
}

const tableStyle = {
  whiteSpace: 'nowrap',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    stations: state.stations.all,
  }
}

const mapDispatchToProps = {
  fetchStations,
}

export default connect(mapStateToProps, mapDispatchToProps)(Stations)
