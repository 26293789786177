import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  fetchSchedules,
  subscribeSchedules,
  unsubscribeSchedules,
  startSchedule,
  stopSchedule,
  runScheduleNow,
} from 'redux/actions'
import { prettyDate } from 'helpers/intervals'

// reactstrap components
import {
  Badge,
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Spinner,
} from 'reactstrap'

const Schedules = props => {
  const {
    schedules,
    fetchSchedules,
    subscribeSchedules,
    unsubscribeSchedules,
    startSchedule,
    stopSchedule,
    runScheduleNow,
  } = props

  useEffect(() => {
    fetchSchedules()
    subscribeSchedules()
    return () => unsubscribeSchedules
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStateChange = schedule => {
    const { active, name } = schedule
    if (active) stopSchedule(name)
    else startSchedule(name)
  }

  const handleRunNow = schedule => {
    const { name } = schedule
    runScheduleNow(name)
  }

  const getBadges = schedule => {
    const Percentage = (
      <>
        {schedule.running && schedule.percentage > 0 && (
          <Badge color="warning">{`${schedule.percentage.toFixed(0)} %`}</Badge>
        )}
        {schedule.error && <Badge color="error">error</Badge>}
      </>
    )
    if (schedule.active || schedule.running)
      return (
        <>
          <Badge
            icon={schedule.running ? <Spinner /> : ''}
            color={schedule.running ? 'primary' : 'success'}>
            active
          </Badge>
          {Percentage}
        </>
      )
    else
      return (
        <>
          <Badge icon={schedule.running ? <Spinner /> : ''} color="secondary">
            stopped
          </Badge>
          {Percentage}
        </>
      )
  }

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card style={cardStyle}>
            <CardBody>
              <Table hover style={tableStyle}>
                <thead className="text-primary">
                  <tr>
                    <th>Next run</th>
                    <th>Name</th>
                    <th>Interval</th>
                    <th>Last run</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {schedules.map((schedule, key) => (
                    <tr key={key}>
                      <td style={{ minWidth: 160 }}>
                        {prettyDate(schedule.lastRun)}
                      </td>
                      <td>{schedule.name}</td>
                      <td>{schedule.interval}</td>
                      <td style={{ minWidth: 160 }}>
                        {prettyDate(schedule.nextRun)}
                      </td>
                      <td>{getBadges(schedule)}</td>
                      <td>
                        <Button
                          outline
                          className="me-2"
                          size="sm"
                          color={schedule.active ? 'danger' : 'primary'}
                          onClick={() => handleStateChange(schedule)}>
                          {schedule.active ? 'Deactivate' : 'Activate'}
                        </Button>
                        <Button
                          size="sm"
                          onClick={() => handleRunNow(schedule)}>
                          Run Now
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const cardStyle = {
  overflow: 'auto',
}

const tableStyle = {
  whiteSpace: 'nowrap',
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    schedules: state.schedules.all,
  }
}

const mapDispatchToProps = {
  fetchSchedules,
  subscribeSchedules,
  unsubscribeSchedules,
  startSchedule,
  stopSchedule,
  runScheduleNow,
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedules)
