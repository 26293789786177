import React, { useState } from 'react'
import { connect } from 'react-redux'
import { inviteUser } from '../../redux/actions'

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

const InviteUser = props => {
  const { label, user, className, inviteUser } = props

  const [modal, setModal] = useState(false)

  const initialUserValues = {
    email: '',
    role: 'Admin',
    noOfLicences: 0,
  }

  const [userValues, setUserValues] = useState(initialUserValues)

  const handleChange = event => {
    setUserValues({
      ...userValues,
      [event.target.id]: event.target.value,
    })
  }

  const toggle = () => {
    if (!modal) {
      setUserValues(user || initialUserValues)
    }
    setModal(!modal)
  }

  const handleInvite = event => {
    event.preventDefault()
    inviteUser(userValues)
    toggle()
  }

  return (
    <div>
      <Button color="primary" onClick={toggle}>
        {label}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>
          <div className="mb-2" style={{ fontSize: 13 }}>
            The user will be invited on the provided email
          </div>
          <Form>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="text"
                id="email"
                value={userValues.email}
                onChange={handleChange}
              />
            </FormGroup>
            {!user && (
              <FormGroup>
                <Label for="role">Role</Label>
                <Input
                  type="select"
                  id="role"
                  value={userValues.role}
                  onChange={handleChange}>
                  <option>User</option>
                  <option>Admin</option>
                </Input>
              </FormGroup>
            )}
            {!user && (
              <FormGroup>
                <Label for="noOfLicences">Number of Licences</Label>
                <Input
                  type="number"
                  id="noOfLicences"
                  value={userValues.noOfLicences}
                  onChange={handleChange}></Input>
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleInvite}>
            Invite
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapDispatchToProps = {
  inviteUser,
}

export default connect(null, mapDispatchToProps)(InviteUser)
