import * as Actions from "../actionTypes";

const initialState = {
    all: [],
    station: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Actions.FETCH_STATIONS.FULFILLED():
        case Actions.CREATE_STATION.FULFILLED(): {
            return {
                ...state,
                all: action.payload.data,
                station: {},
            };
        }
        case Actions.FETCH_STATION.FULFILLED(): {
            return {
                ...state,
                station: action.payload.data,
            };
        }
        case Actions.DELETE_STATION.FULFILLED(): {
            return {
                ...state,
                station: { ...state.station, deleted: true },
            };
        }
        default:
            return state;
    }
}
