import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { fetchStations, fetchStationTables, filter } from '../../../redux/actions';
import {
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';

const Source = (props) => {
    const { stations, selectedStation, tables, selectedTable, fields, selectedFields, fetchStations, fetchStationTables, filter } = props;

    useEffect(() => {
        if (stations.length === 1) {
            filter({
                station: { id: stations[0].id },
                table: {},
                fields: [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stations]);

    useEffect(() => {
        const availableTables = tables.filter(table => table.collection_enabled);
        if (availableTables.length === 1) {
            const table = availableTables[0];
            filter({
                table: { id: table.id, table_name: table.name },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tables]);

    useEffect(() => {
        fetchStations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedStation.id) {
            fetchStationTables(selectedStation.id);            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStation]);

    const handleStationChange = event => {
        filter({
            station: { id: event.target.value },
            table: {},
            fields: [],
        });
    }

    const handleTableChange = event => {
        var newTable;
        for (const table of tables) {
            if (table.id === Number(event.target.value))
                newTable = { id: table.id, table_name: table.name };
        }
        filter({
            table: newTable,
            fields: [],
        });
    }

    const handleFieldChange = (event, field) => {
        var fields;
        if (event.target.checked) {
            fields = [...selectedFields, field]
        }
        else {
            fields = selectedFields.filter(f => f.name !== field.name)
        }
        filter({
            fields,
        });
    }

    return (
        <Form>
            <FormGroup>
                <Label>Station</Label>
                <Input
                    type="select"
                    id="owner_id"
                    value={selectedStation.id}
                    onChange={handleStationChange}
                >
                    <option value={0}>--Select Station--</option>
                    {stations.map(station =>
                        <option
                            key={station.id}
                            value={Number(station.id)}
                        >
                            {station.alias}
                        </option>
                    )}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label>Table</Label>
                <Input
                    type="select"
                    id="owner_id"
                    value={selectedTable.id}
                    onChange={handleTableChange}
                >
                    <option value={0}>--Select Table--</option>
                    {tables.filter(table => table.collection_enabled).map(table =>
                        <option
                            key={table.id}
                            value={Number(table.id)}
                        >
                            {table.name}
                        </option>
                    )}
                </Input>
            </FormGroup>
            {selectedTable.id ?
                <div>
                    <Label>Fields</Label>
                    {fields.map((field, index) =>
                        <FormGroup check key={selectedTable.id + '-' + index}>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    style={{ opacity: 0.5, visibility: 'visible' }}
                                    defaultChecked={selectedFields.filter(selectedField => selectedField.name === field.name).length > 0}
                                    onChange={(event) => handleFieldChange(event, field)}
                                />{' '}
                                {field.name}
                            </Label>
                        </FormGroup>
                    )}
                </div> : ''
            }
        </Form>
    );
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        stations: state.stations.all,
        selectedStation: state.filters.station,
        tables: state.tables.all,
        selectedTable: state.filters.table,
        fields: state.fields.all,
        selectedFields: state.filters.fields,
    }
}

const mapDispatchToProps = {
    fetchStations, fetchStationTables, filter
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Source);
