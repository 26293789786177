const buildURLQuery = (filters) => {
    const params = paramsToURL(filters);
    return question(params) + params
}

const and = (params) => {
    return params.length > 0 ? '&' : '';
}

const question = (params) => {
    return params.length > 0 ? '?' : '';
}

const paramsToURL = (query) => {
    if (!query)
        return "";
    var params = "";
    for (const [key, value] of Object.entries(query)) {
        if (typeof value === 'object' && value !== null) {
            params += and(params) + paramsToURL(value);
        }
        else {
            params += and(params) + key + "=" + value;
        }
    }
    return params;
}

export { buildURLQuery };