import React, { useState } from 'react'
import { Collapse, ListGroup, ListGroupItem } from 'reactstrap'

import Source from './Source'
import View from './View/View'
import Caret from 'components/CustomUI/Caret'

const listStyle = {
  marginBottom: 15 + 'px',
}

const scrollableCollapseStyle = {
  maxHeight: 66 + 'vh',
  overflow: 'auto',
}

const OptionsBar = props => {
  const initialState = {
    view: true,
    source: true,
  }

  const [state, setState] = useState(initialState)

  const toggle = key => {
    setState({
      ...state,
      [key]: !state[key],
    })
  }

  return (
    <>
      <View />
      <ListGroup style={listStyle}>
        <ListGroupItem
          active={state.source}
          onClick={() => toggle('source')}
          tag="a"
          href="#"
          action>
          <Caret down={!state.source} /> Source
        </ListGroupItem>
        <Collapse isOpen={state.source} style={scrollableCollapseStyle}>
          <ListGroupItem>
            <Source />
          </ListGroupItem>
        </Collapse>
      </ListGroup>
    </>
  )
}

export default OptionsBar
